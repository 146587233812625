<script>

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      randomRows: []
    }
  },
  methods:{
    generateRandomRows() {
      const numberOfRows = 12;

      const dates = ["2023-12-01 22:52", "2023-11-02 15:30", "2023-12-03 09:45"];
      const symbols = ["BTC/USDT", "ETH/USDT", "XRP/USDT"];
      const markers = ["Limit marker", "Market marker", "Stop marker"];
      const actions = ["Buy", "Sell"];
      const prices = [280.0, 300.0, 260.0];
      const quantities = [0.050, 0.030, 0.070];
      const percentages = ["0.00%", "1.50%", "-0.75%"];
      const profits = ["14.0USDT", "20.0USDT", "-5.0USDT"];
      const conditions = ["Mark price >= 810.0", "Mark price <= 750.0", "Volume > 100"];

      this.randomRows = Array.from({length: numberOfRows}, (_, index) => {
        return {
          id: index,
          date: dates[Math.floor(Math.random() * dates.length)],
          symbol: symbols[Math.floor(Math.random() * symbols.length)],
          marker: markers[Math.floor(Math.random() * markers.length)],
          action: actions[Math.floor(Math.random() * actions.length)],
          price: prices[Math.floor(Math.random() * prices.length)],
          quantity: quantities[Math.floor(Math.random() * quantities.length)],
          percentage: percentages[Math.floor(Math.random() * percentages.length)],
          profit: profits[Math.floor(Math.random() * profits.length)],
          condition: conditions[Math.floor(Math.random() * conditions.length)],
        };
      });
    }
  },
  created() {
    this.generateRandomRows();
  }
}
</script>

<template>
  <Transition name="orders-modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-c">
          <div class="modal-header">
            <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
              <h4 class="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-white">
                History
              </h4>
              <button @click="$emit('close')">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                  <path
                      d="M17.5302 7.53033C17.8231 7.23744 17.8231 6.76256 17.5302 6.46967C17.2373 6.17678 16.7624 6.17678 16.4696 6.46967L11.9999 10.9393L7.53022 6.46967C7.23732 6.17678 6.76245 6.17678 6.46956 6.46967C6.17666 6.76256 6.17666 7.23744 6.46956 7.53033L10.9392 12L6.46955 16.4697C6.17665 16.7626 6.17665 17.2374 6.46955 17.5303C6.76244 17.8232 7.23731 17.8232 7.53021 17.5303L11.9999 13.0607L16.4696 17.5303C16.7625 17.8232 17.2373 17.8232 17.5302 17.5303C17.8231 17.2374 17.8231 16.7626 17.5302 16.4697L13.0605 12L17.5302 7.53033Z"
                      fill="white"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div class="modal-body-c">
            <div class="flex justify-start items-end self-stretch flex-grow-0 flex-shrink-0 gap-4 mb-4">
              <div class="flex justify-start items-end flex-grow relative">
                <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                  <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 px-2">
                    <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                      Open Orders ({{ randomRows.length }})
                    </p>
                  </div>
                  <div class="self-stretch flex-grow-0 flex-shrink-0 h-0.5 bg-[#2962ff]"></div>
                </div>
                <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                  <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 px-2">
                    <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#2a2e39]">
                      Order History
                    </p>
                  </div>
                  <div class="self-stretch flex-grow-0 flex-shrink-0 h-0.5 bg-[#2a2e39]"></div>
                </div>
                <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                  <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 px-2">
                    <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#2a2e39]">
                      Trade History
                    </p>
                  </div>
                  <div class="self-stretch flex-grow-0 flex-shrink-0 h-0.5 bg-[#2a2e39]"></div>
                </div>
                <div class="flex-grow h-0.5 bg-[#2a2e39]"></div>
              </div>
            </div>

            <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6">
              <div class="overflow-x-auto relative shadow-md sm:rounded-lg w-full">
                <table class="w-full text-sm text-left">
                  <thead class="text-xs text-[#2A2E39] uppercase">
                  <tr class="border-b border-[#2A2E39] text-gray-500">
                    <th scope="col" class="py-3 px-6">
                      Date
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Pair
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Type
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Side
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Amount
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Filled
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Total
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Trigger Conditions
                    </th>
                    <th scope="col" class="py-3 px-6">

                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- Repeat this tr for each row -->
                  <tr v-for="row in randomRows" class="border-b border-[#2A2E39]">
                    <td scope="row" class="py-4 px-6 font-medium whitespace-nowrap text-white">
                      {{ row.date }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.symbol }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.marker }}
                    </td>
                    <td class="py-4 px-6" :class="{'text-[#26A69A]' : row.action === 'Buy','text-[#EF5350]' : row.action === 'Sell',}">
                      {{ row.action }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.price }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.quantity }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.percentage }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.profit }}
                    </td>
                    <td class="py-4 px-6 text-white">
                      {{ row.condition }}
                    </td>
                    <td class="py-4 px-6">
                      <button class="flex justify-end items-center flex-grow relative">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.57143 2H13.4286V3.14286H18V4.28571H15.7143V18H4.28571V4.28571H2V3.14286H6.57143V2ZM8.85714 6.57143H7.71429V15.7143H8.85714V6.57143ZM11.1429 6.57143H12.2857V15.7143H11.1429V6.57143Z"
                              fill="#2962FF"
                          ></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-c {
  width: 1350px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #131722;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 95vw;
}

@media (max-width: 450px) {
  .modal-container-c {
    max-width: 90vw;
  }
}

.modal-header h3, .modal-header .title {
  color: #b2b5be;
  font-size: 20px;
  margin-top: 0;
}

.modal-body-c {
  margin: 20px 0;
  max-height: 85vh;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-c,
.modal-leave-to .modal-container-c {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
