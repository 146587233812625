<template>
  <section :class="{'active-sidebar' : this.sidebar.is_active}">
    <!--
        <div id="custom-sidebar">
          <ul style="padding: 0;text-align: center;margin:0">
            <li @click="sidebarItemClick(1)" :class="{'active' : this.sidebar.active_menu === 1}">
              <a href="#">
                <svg height="45" width="45" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m29.5 11a4.5 4.5 0 0 1 4.5 4.5v14a4.5 4.5 0 0 1-4.5 4.5h-15a4.5 4.5 0 0 1-4.5-4.5v-14a4.5 4.5 0 0 1 4.5-4.5m0 1a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5h15a3.5 3.5 0 0 0 3.5-3.5v-14a3.5 3.5 0 0 0-3.5-3.5M15 17v-1h14v1zm0 4v-1h14v1zm0 4v-1h14v1zm0 4v-1h14v1z"
                      fill="currentcolor"></path>
                </svg>
              </a>
            </li>
            <li @click="sidebarItemClick(2)" :class="{'active' : this.sidebar.active_menu === 2}">
              <a href="#">
                <svg height="45" width="45" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M22.5 12a1 1 0 0 1 0 21 1 1 0 0 1 0-21m0 1a1 1 0 0 0 0 19 1 1 0 0 0 0-19m-7.15 16.85l.8.6-2.05 2.75-.8-.6zm13.48.57l.8-.6 2.07 2.78-.8.6zM22 22v-7h1v8h-6v-1zm9.93-2.95l-.58-.81a4 4 0 1 0-5.84-5.2l-.87-.49a5 5 0 1 1 7.3 6.5zm-11.57-6.5l-.87.48a4 4 0 1 0-5.9 5.17l-.6.8a5 5 0 1 1 7.36-6.46z"
                      fill="currentcolor"></path>
                </svg>
              </a>
            </li>
            <li @click="sidebarItemClick(3)" :class="{'active' : this.sidebar.active_menu === 3}">
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44" height="44">
                  <path fill="currentColor"
                        d="M33.8 15.61a.93.93 0 0 1 1.12 1.49l-7.42 5.77-7.42-5.77a.93.93 0 0 1 1.11-1.49l6.02 4.3.29.2.29-.2 6.02-4.3Zm1.9-.6a1.93 1.93 0 0 0-2.48-.21l-5.72 4.09-5.72-4.1a1.93 1.93 0 0 0-2.31 3.1l7.72 6 .31.24.3-.24 7.73-6c.91-.71 1-2.07.18-2.89ZM22.8 29.38a.93.93 0 0 0 1.12-1.49l-7.42-5.77-7.42 5.77a.93.93 0 0 0 1.11 1.49l6.02-4.3.29-.2.29.2 6.02 4.3Zm1.9.6c-.66.67-1.7.76-2.48.21l-5.72-4.09-5.72 4.1a1.93 1.93 0 0 1-2.31-3.1l7.72-6 .31-.24.3.24 7.73 6c.91.71 1 2.07.18 2.89Z"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div id="siderbar-data">
          <div
              style="height: 100vh; width: 100%; background-color: rgb(19, 23, 34); display: flex;"
          class="">
            <section v-if="this.sidebar.active_menu === 1" style="color: red;margin: 0">
            </section>
            <div v-if="this.sidebar.active_menu === 2">
            </div>
            <div v-if="this.sidebar.active_menu === 3" class="w-full overflow-x-auto">
              <form-basic-order class="p-4"></form-basic-order>
              <order-book></order-book>
            </div>
          </div>
        </div>
    -->
    <div :id="container" class="TVChartContainer" :class="{'no-sidebar' : !this.sidebar.enable}"/>
    <!--
        <orders-table
            v-if="this.sidebar.is_active && this.sidebar.active_menu === 3 && !dOrder.showOrdersHistoryModal"
            @seeAll="dOrder.showOrdersHistoryModal = true"></orders-table>
        <order-modal :show="dOrder.showModal" v-if="dOrder.showModal" @close="dOrder.showModal = false">
        </order-modal>
        <analyz-modal :show="dOrder.showAnalyzModal" @close="dOrder.showAnalyzModal = false">
        </analyz-modal>
        <orders-modal :show="dOrder.showOrdersHistoryModal" @close="dOrder.showOrdersHistoryModal = false"></orders-modal>
    -->
  </section>
</template>

<script>
import api from './api'
import axios from 'axios'
import moment from 'moment';
import OrderBook from "@/components/partials/orderBook";
import OrderModal from "@/components/partials/orderModal";
import AnalyzModal from "@/components/partials/analyzModal";
import OrdersTable from "@/components/partials/ordersTable";
import OrdersModal from "@/components/partials/ordersModal";
import FormBasicOrder from "@/components/partials/formBasicOrder";

export default {
  components: {FormBasicOrder, OrdersModal, OrdersTable, AnalyzModal, OrderModal, OrderBook},
  props: {
    signalId: {
      type: String,
    },
    symbol: {
      default: 'GALAUSDT',
      type: String,
    },
    interval: {
      default: 3,
      type: Number,
    },
    debug: {
      default: false,
      type: Boolean,
    },
    container: {
      default: 'tv_chart_container',
      type: String,
    },
    libraryPath: {
      default: '/charting_library/charting_library/',
      type: String,
    },
    fullscreen: {
      default: true,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    }
  },
  tvWidget: null,
  data() {
    return {
      options: {
        width: 780,
        height: 540,
      },
      theme: "day",
      data: {
        buy: [
          {price: 10977.76, amount: 0.303},
          {price: 10977.63, amount: 0.462},
        ],
        sell: [
          {price: 10986, amount: 52.896},
          {price: 10985.91, amount: 48.57},
        ],
      },
      signal_price: 0,
      signal_side: null,
      signal_candle: null,
      strategy_code: 0,
      hgl_price: 0,
      dinamicOrderStatus: false,
      sidebar: {
        enable: false,
        is_active: false,
        active_menu: 2,
      },
      dOrder: { // todo | set defaults
        showOrdersHistoryModal: false,
        showAnalyzModal: false,
        showModal: false,
        invalid: false,
        order_type: 1,
        trading_type: 1,
        entity: {
          amount: 0,
          price: 0,
          stop: 0,
          limit: 0,
          percent: 44,
        }
      }
    }
  },
  methods: {
    sidebarItemClick(menu_index = 0) {
      if (menu_index === 0) {
        return;
      }

      if (this.sidebar.active_menu === menu_index) {
        this.sidebar.is_active = false;
        this.sidebar.active_menu = 0;
        return;
      }

      this.sidebar.is_active = true;
      this.sidebar.active_menu = menu_index;

    },
    getSignalData(widget) {
      if (this.signalId === null) {
        return;
      }
      axios.post(`https://nextapi.finomate.io/api/v1/info/signal_by_id?signal_id=${this.signalId}`)
          .then(response => {
            let data = response.data;
            this.signal_price = data.signal_price;
            this.signal_side = data.signal_side;
            this.signal_candle = data.signal_candle;
            this.strategy_code = data.strategy_code;

            if (![18, 35, 38].includes(this.strategy_code))
              this.checkLastStatus(widget);

            let epLines = [34, 35, 36, 37, 38];
            if (epLines.includes(this.strategy_code))
              this.drawEpLine(widget);

            let lineTags = [];
            // check strategy
            if (this.strategy_code == 24)
              lineTags = ['sl', 'tp1', 'tp2', 'tp3'];
            if (this.strategy_code == 35)
              lineTags = ['sl', 'tp1', 'tp2'];
            if (this.strategy_code == 36)
              lineTags = ['sl'];
            if (this.strategy_code == 37)
              lineTags = ['tp1', 'sl'];
            if ([14, 31, 32, 33, 34, 35, 38].includes(this.strategy_code))
              lineTags = ['tp1', 'sl'];

            this.drawTpSlLines(this.tvWidget, lineTags);

            let mLines = [14, 24, 34, 35, 36, 37];
            if (mLines.includes(this.strategy_code))
              widget.activeChart().createStudy('MACD');

            if (this.strategy_code === 18 || this.strategy_code === 38) {
              axios.post(`https://nextapi.finomate.io/api/v1/info/channel?signal_id=${this.signalId}`)
                  .then(response => {
                    let data = response.data;
                    let line1_date1 = moment.utc(data.Line1.X1Date);
                    let line1_date2 = moment.utc(data.Line1.X2Date);
                    let line2_date1 = moment.utc(data.Line2.X1Date);
                    let line2_date2 = moment.utc(data.Line2.X2Date);
                    let line1_points = [
                      {time: line1_date1.unix(), price: parseFloat(data.Line1.Y1)},
                      {time: line1_date2.unix(), price: parseFloat(data.Y2E1)}
                    ];
                    let line2_points = [
                      {time: line2_date1.unix(), price: parseFloat(data.Line2.Y1)},
                      {time: line2_date2.unix(), price: parseFloat(data.Y2E2)}
                    ];

                    let line1_arrow_id = widget.activeChart().createMultipointShape(line1_points, {
                      shape: "trend_line",
                      lock: false,
                      disableSelection: false,
                      disableSave: false,
                      disableUndo: true
                    });
                    let line2_arrow_id = widget.activeChart().createMultipointShape(line2_points, {
                      shape: "trend_line",
                      lock: false,
                      disableSelection: false,
                      disableSave: false,
                      disableUndo: true
                    });
                    widget.activeChart().bringToFront([line1_arrow_id]);
                    widget.activeChart().bringToFront([line2_arrow_id]);
                  })
                  .catch(e => {
                    console.error(e);
                  });
            }

            if (this.strategy_code === 15 || this.strategy_code === 35) {
              axios.post(`https://nextapi.finomate.io/api/v1/info/zone?signal_id=${this.signalId}`)
                  .then(response => {
                    let data = response.data;

                    let points = [
                      {time: moment.utc(data.start_candle).unix(), price: parseFloat(data.zone_up)},
                      {time: moment.utc(this.signal_candle).unix(), price: parseFloat(data.zone_down)}
                    ];

                    let rectangle_id = widget.activeChart().createMultipointShape(points, {
                      shape: "rectangle",
                      lock: false,
                      disableSelection: false,
                      disableSave: false,
                      disableUndo: true
                    });
                    widget.activeChart().bringToFront([rectangle_id]);
                  })
                  .catch(e => {
                    console.error(e);
                  });
            }
          })
          .catch(e => {
            console.error(e);
          });
    },
    drawEpLine(widget) {
      widget.activeChart().createPositionLine()
          .setText("Entry Price")
          .setQuantity()
          .setPrice(this.signal_price)
          .setLineStyle(0)
          .setLineColor('rgb(255,227,55)')
          .setBodyBackgroundColor('rgb(255,227,55)')
          .setBodyBorderColor('rgb(255,227,55)')
          .setBodyTextColor('rgb(0,0,0)')
          .setLineLength(2)
          .setLineWidth(2);
    },
    drawTpSlLines(widget, tags) {
      axios.post(`https://nextapi.finomate.io/api/v1/info/sltp`, {
        signal_id: this.signalId,
        tags: tags,
      })
          .then(response => {
            let data = response.data;

            if (data.sl && data.sl.price) {
              widget.activeChart().createPositionLine()
                  .setText("SL")
                  .setQuantity()
                  .setPrice(data.sl.price)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,25,0)')
                  .setBodyBackgroundColor('rgb(255,25,0)')
                  .setBodyBorderColor('rgb(255,25,0)')
                  .setBodyTextColor('rgb(255,255,255)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
            if (data.tp1 && data.tp1.price) {
              widget.activeChart().createPositionLine()
                  .setText("TP1")
                  .setQuantity()
                  .setPrice(data.tp1.price)
                  .setLineStyle(0)
                  .setLineColor('rgb(5,248,36)')
                  .setBodyBackgroundColor('rgb(5,248,36)')
                  .setBodyBorderColor('rgb(5,248,36)')
                  .setBodyTextColor('rgb(255,255,255)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
            if (data.tp2 && data.tp2.price) {
              widget.activeChart().createPositionLine()
                  .setText("TP2")
                  .setQuantity()
                  .setPrice(data.tp2.price)
                  .setLineStyle(0)
                  .setLineColor('rgb(5,248,36)')
                  .setBodyBackgroundColor('rgb(5,248,36)')
                  .setBodyBorderColor('rgb(5,248,36)')
                  .setBodyTextColor('rgb(255,255,255)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
            if (data.tp3 && data.tp3.price) {
              widget.activeChart().createPositionLine()
                  .setText("TP3")
                  .setQuantity()
                  .setPrice(data.tp3.price)
                  .setLineStyle(0)
                  .setLineColor('rgb(5,248,36)')
                  .setBodyBackgroundColor('rgb(5,248,36)')
                  .setBodyBorderColor('rgb(5,248,36)')
                  .setBodyTextColor('rgb(255,255,255)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }

            var tagsData = data;
            this.drawArrowOnChart(widget, tagsData);
          })
          .catch(e => {
            console.error(e);
          });
    },
    drawArrowOnChart(widget, tagsData) {
      if (this.signal_side == 'BUY') {
        var arrow_id = widget.activeChart().createShape({
          time: moment.utc(this.signal_candle).unix(),
          price: this.signal_price * 0.998
        }, {
          shape: "arrow_up",
          lock: false,
          disableSelection: true,
          disableSave: false,
          disableUndo: true,
          text: 'BUY' + ' _ ' + this.signal_price
        })
        var arrow = widget.activeChart().getShapeById(arrow_id);
        arrow.setProperties({
          color: '#26a69a',
          arrowColor: '#26a69a',
          fontsize: 20,
          bold: false,
          italic: false,
        });

        if (tagsData.sl && tagsData.tp1)
          this.hgl_price = tagsData.sl.price - (tagsData.tp1.price - this.signal_price);
      }
      if (this.signal_side == 'SELL') {
        var arrow_id = widget.activeChart().createShape({
          time: moment.utc(this.signal_candle).unix(),
          price: this.signal_price * 1.002
        }, {
          shape: "arrow_down",
          lock: false,
          disableSelection: true,
          disableSave: false,
          disableUndo: true,
          text: 'SELL' + ' _ ' + this.signal_price
        })
        var arrow = widget.activeChart().getShapeById(arrow_id);
        arrow.setProperties({
          color: '#ef5350',
          arrowColor: '#ef5350',
          fontsize: 20,
          bold: false,
          italic: false,
        });

        if (tagsData.sl && tagsData.tp1)
          this.hgl_price = tagsData.sl.price + (this.signal_price - tagsData.tp1.price);
      }

      if (this.strategy_code == 14)
        this.drawHGLine(widget);
    },
    drawHGLine(widget) {
      widget.activeChart().createPositionLine()
          .setText("")
          .setQuantity()
          .setPrice(this.hgl_price)
          .setLineStyle(0)
          .setLineColor('rgb(255,255,255)')
          .setBodyBackgroundColor('rgb(255,255,255)')
          .setBodyBorderColor('rgb(255,255,255)')
          .setBodyTextColor('rgb(0,0,0)')
          .setLineLength(2)
          .setLineWidth(2);
    },
    checkLastStatus(widget) {
      if (this.signalId === null) {
        return;
      }

      axios.post(`https://nextapi.finomate.io/api/v1/info/last_status?signal_id=${this.signalId}`)
          .then(response => {
            let data = response.data;

            let status = data.toLowerCase();
            if (status == 'close') {
              axios.post(`https://nextapi.finomate.io/api/v1/info/closing_date?signal_id=${this.signalId}`)
                  .then(response => {
                    let data = response.data;

                    let last_kline = moment.utc(data).unix();
                    last_kline = parseInt(last_kline) * 1000;
                    this.drawParallelChannel(widget, last_kline);
                  })
                  .catch(e => {
                    console.error(e);
                  });
            } else {
              let thiz = this;

              const last_kline = parseInt(localStorage.getItem('last-kline'));

              thiz.drawParallelChannel(widget, last_kline);
              window.timer = setInterval(function () {
                thiz.drawParallelChannel(widget, last_kline, false);
              }, 31000);
            }
          })
          .catch(e => {
            console.error(e);
          });
    },
    drawFibo(widget) {
      if (this.signalId === null) {
        return;
      }

      axios.post(`https://nextapi.finomate.io/api/v1/info/fibo_drawing?signal_id=${this.signalId}`)
          .then(response => {
            let data = response.data;
            let date_a = moment.utc(data.fib_a_date);
            let date_b = moment.utc(data.fib_b_date);
            let fibo_points = [
              {
                time: date_a.unix(), price: data.fib_a
              },
              {
                time: date_b.unix(), price: data.fib_b
              }
            ];
            widget.activeChart().createMultipointShape(fibo_points, {
              shape: "fib_retracement",
              lock: false,
              disableSelection: false,
              disableSave: false,
              disableUndo: true,
            });
          })
          .catch(e => {
            console.error(e);
          });
    },
    drawParallelChannel(widget, last_kline, drawArrows = true) {
      if (this.signalId === null) {
        return;
      }


      if (last_kline && last_kline > 0) {
        axios.post(`https://nextapi.finomate.io/api/v1/info/channel_drawing?signal_id=${this.signalId}`)
            .then(response => {
              let data = response.data;

              if (data.a_value && parseFloat(data.a_value) > 0) {
                let date_a = moment.utc(data.a_date);
                let date_b = moment.utc(data.b_date);
                let date_c = moment.utc(data.c_date);

                let date_last_kline = moment.utc(last_kline);
                let diff = date_last_kline.diff(date_b, 'minutes') / this.interval;

                let b_value = (data.b_slope * (data.b_x + diff)) + data.b_origin;
                let c_value = (data.c_slope * (data.c_x + diff)) + data.c_origin;

                let points = [
                  {
                    time: date_a.unix(), price: data.a_value
                  },
                  {
                    time: date_last_kline.unix(), price: b_value
                  },
                  {
                    time: date_last_kline.unix(), price: c_value
                  }
                ];
                if (window.p_channel)
                  window.p_channel.setPoints(points);
                else {
                  let p_channel_id = window.tvChart.activeChart().createMultipointShape(points, {
                    shape: "parallel_channel",
                    lock: false,
                    disableSelection: false,
                    disableSave: false,
                    disableUndo: true,
                  });
                  window.p_channel = widget.activeChart().getShapeById(p_channel_id);
                }
              }
            })
            .catch(e => {
              console.error(e);
            });

        if (drawArrows)
          this.drawArrowsOnIndicators(widget);
      }
    },
    drawArrowsOnIndicators(widget) {
      if (this.signalId === null) {
        return;
      }

      axios.post(`https://nextapi.finomate.io/api/v1/info/get_param?signal_id=${this.signalId}`)
          .then(response => {
            let data = response.data;

            // checkingRSIDiv
            if (data.rsi_div && data.rsi_div == 1) {
              var thiz = this;
              let rsiStudy = widget.activeChart().createStudy('Relative strength index');
              rsiStudy.then(response => {
                thiz.drawRSIArrow(widget, response, data);
              });
            }

            const checkingMacdHistDiv = data.macd_hist_div && data.macd_hist_div == 1;
            const checkingSigLineDiv = data.sig_line_div && data.sig_line_div == 1;
            if (checkingMacdHistDiv || checkingSigLineDiv) {
              var thiz = this;
              let macdStudy = widget.activeChart().createStudy('MACD');
              macdStudy.then(response => {
                thiz.drawMACDArrow(widget, response, data, checkingMacdHistDiv, checkingSigLineDiv);
              });
            }

            // checkingStochDiv
            if (data.stoch_div && data.stoch_div == 1) {
              var thiz = this;
              let StochStudy = widget.activeChart().createStudy('stochastic');
              StochStudy.then(response => {
                thiz.drawStochArrow(widget, response, data);
              });
            }


            // createPositionLine[Mart`1,Mart2,Mart3]
            if (this.strategy_code == 34) {
              widget.activeChart().createPositionLine()
                  .setText("Mart1")
                  .setQuantity()
                  .setPrice(data.mart1)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
              widget.activeChart().createPositionLine()
                  .setText("Mart2")
                  .setQuantity()
                  .setPrice(data.mart2)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
              widget.activeChart().createPositionLine()
                  .setText("Mart3")
                  .setQuantity()
                  .setPrice(data.mart3)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
              widget.activeChart().createPositionLine()
                  .setText("Mart4")
                  .setQuantity()
                  .setPrice(data.mart4)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
            if (this.strategy_code == 35) {
              widget.activeChart().createPositionLine()
                  .setText("Virt")
                  .setQuantity()
                  .setPrice(data.virt)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
              widget.activeChart().createPositionLine()
                  .setText("Virt 2")
                  .setQuantity()
                  .setPrice(data.virt2)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
            if (this.strategy_code == 37) {
              widget.activeChart().createPositionLine()
                  .setText("Mart")
                  .setQuantity()
                  .setPrice(data.mart)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
              widget.activeChart().createPositionLine()
                  .setText("Mart Price")
                  .setQuantity()
                  .setPrice(data.mart_price)
                  .setLineStyle(0)
                  .setLineColor('rgb(255,255,255)')
                  .setBodyBackgroundColor('rgb(255,255,255)')
                  .setBodyBorderColor('rgb(255,255,255)')
                  .setBodyTextColor('rgb(0,0,0)')
                  .setLineLength(2)
                  .setLineWidth(2);
            }
          })
          .catch(e => {
            console.error(e);
          });
    },
    drawMACDArrow(widget, macdId, data, checkingMacdHistDiv, checkingSigLineDiv) {
      // checkingMacdHistDiv
      if (checkingMacdHistDiv) {
        let mh_date_a = moment.utc(data.mh_a_date);
        let mh_date_b = moment.utc(data.mh_b_date);
        let mh_points = [
          {time: mh_date_a.unix(), price: parseFloat(data.mh_a)},
          {time: mh_date_b.unix(), price: parseFloat(data.mh_b)}
        ];
        let mh_arrow_id = widget.activeChart().createMultipointShape(mh_points, {
          shape: "arrow",
          lock: false,
          disableSelection: false,
          disableSave: false,
          disableUndo: true,
          ownerStudyId: macdId
        });
        widget.activeChart().bringToFront([mh_arrow_id]);
      }

      // checkingSigLineDiv
      if (checkingSigLineDiv) {
        let sig_line_date_a = moment.utc(data.sig_line_a_date);
        let sig_line_date_b = moment.utc(data.sig_line_b_date);
        let sig_line_points = [
          {
            time: sig_line_date_a.unix(),
            price: parseFloat(data.sig_line_a)
          },
          {
            time: sig_line_date_b.unix(),
            price: parseFloat(data.sig_line_b)
          }
        ];

        widget.activeChart().createMultipointShape(sig_line_points, {
          shape: "arrow",
          lock: false,
          disableSelection: false,
          disableSave: false,
          disableUndo: true,
          ownerStudyId: macdId
        });
      }
    },
    drawRSIArrow(widget, rsiId, data) {
      let rsi_date_a = moment.utc(data.rsi_a_date);
      let rsi_date_b = moment.utc(data.rsi_b_date);
      let rsi_points = [
        {time: rsi_date_a.unix(), price: parseFloat(data.rsi_a)},
        {time: rsi_date_b.unix(), price: parseFloat(data.rsi_b)}
      ];

      let rsi_arrow_id = widget.activeChart().createMultipointShape(rsi_points, {
        shape: "arrow",
        lock: false,
        disableSelection: false,
        disableSave: false,
        disableUndo: true,
        ownerStudyId: rsiId
      });
      widget.activeChart().bringToFront([rsi_arrow_id]);
    },
    drawStochArrow(widget, stochStudyId, data) {
      let stoch_date_a = moment.utc(data.stoch_a_date);
      let stoch_date_b = moment.utc(data.stoch_b_date);
      let stoch_points = [
        {time: stoch_date_a.unix(), price: parseFloat(data.stoch_a)},
        {time: stoch_date_b.unix(), price: parseFloat(data.stoch_b)}
      ];

      let stoch_arrow_id = widget.activeChart().createMultipointShape(stoch_points, {
        shape: "arrow",
        lock: false,
        disableSelection: false,
        disableSave: false,
        disableUndo: true,
        ownerStudyId: stochStudyId
      });
      widget.activeChart().bringToFront([stoch_arrow_id]);
    }
  },
  async mounted() {
    const widgetOptions = {
      symbol: this.symbol,
      datafeed: api,
      interval: this.interval,
      container: this.container,
      library_path: this.libraryPath,

      locale: 'en',
      theme: 'dark',
      disabled_features: ['header_compare'],
      enabled_features: ['header_indicators', 'header_saveload', 'header_symbol_search', 'header_settings'],
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
      timezone: 'UTC',
      charts_storage_url: 'https://saved.finomate.io',
      auto_save_delay: 1,
      load_last_chart: false,
      client_id: 'finochart',
      user_id: '0',
    }
    // disabled_features: ['go_to_date', 'header_symbol_search', 'header_compare', 'header_indicators', 'header_settings', 'header_saveload'],
    // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone

    localStorage.removeItem('last-kline');
    this.tvWidget = new TradingView.widget(widgetOptions)

    this.tvWidget.onChartReady(() => {
      window.tvChart = this.tvWidget;
      // console.clear();

      window.tvChart.subscribe('onAutoSaveNeeded', function (a, b) {
        window.tvChart.saveChartToServer();
      });


      if (this.debug) {
        // tip // right click
        /*
                this.tvWidget.onContextMenu((unixTime, price) => {
                  console.debug(unixTime);
                  console.debug(price);

                  const separatorStyle = "border-top: 1px solid #CCCCCC; margin-top: 5px;"; // Styling for the separator

                  return [
                    {
                      position: "top",
                      text: "top", // Line separator text
                      click: function () {
                        console.debug('top')
                      }
                    },
                    {
                      position: "center",
                      text: "center", // Line separator text
                      click: function () {
                        console.debug('center')
                      }
                    },
                    {
                      position: "bottom",
                      text: "bottom",
                      click: function () {
                        console.debug('bottom')
                      }
                    },
                  ];
                });
        */

        // window.tvChart.subscribe('drawing_event', function (a, b) {
        //   console.log(a);
        //   console.log(b);
        // });


        let thiz = this;

        /*        const sendButton = window.tvChart.createButton();
                sendButton.setAttribute('title', 'Click to Send Data');
                sendButton.classList.add('apply-common-tooltip');
                sendButton.addEventListener('click', () => {
                  console.debug(window.tvChart.activeChart().selection()._model.selection()._items);
                });
                sendButton.innerHTML = 'Send Data';

                sendButton.parentElement.parentElement.style = 'display : show';*/

        const showAnalyz = window.tvChart.createButton();
        showAnalyz.setAttribute('title', 'Click to Show Analyz');
        showAnalyz.classList.add('apply-common-tooltip');
        showAnalyz.addEventListener('click', () => {
          thiz.dOrder.showAnalyzModal = true;
        });
        showAnalyz.innerHTML = 'Show Analyz';

        showAnalyz.parentElement.parentElement.style = 'display : show';

        window.timer2 = setInterval(function () {
          let selectionLength = window.tvChart.activeChart().selection()._model.selection()._items.length;
          let iframe = window.tvChart._iFrame.contentWindow;
          if (selectionLength > 0) {
            let tbody = iframe.document.querySelector(".context-menu table tbody");
            let hasTr = iframe.document.querySelector(".context-menu table tbody .custom-tr");
            if (tbody && hasTr === null) {
              let tr = document.createElement("tr");
              tr.setAttribute('class', 'custom-tr item-f5BaKrKq interactive-f5BaKrKq');

              let tdZ = tr.insertCell(0);
              tdZ.setAttribute('class', 'iconCell-f5BaKrKq');
              tdZ.setAttribute('data-icon-cell', true);

              let td = tr.insertCell(1);
              td.innerHTML = '<div class="content-f5BaKrKq"><span class="label-f5BaKrKq" data-label="true">Open Order</span></div>';
              td.addEventListener('click', () => {
                iframe.document.getElementById('overlap-manager-root').remove();
                thiz.dOrder.showModal = true;
              });
              tbody.prepend(tr);
            }
          }
        }, 500);
      }

      if (window.has_exchange) {
        this.getSignalData(this.tvWidget);
      }
    });
  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove()
      this.tvWidget = null
    }
  }
}
</script>