import Vue from 'vue';
import App from './App'

import './main.css';

const app = new Vue({
    el: '#app',
    render(h) {
        return h(App)
    }
})

Vue.config.productionTip = false