<template>
  <div>
    <TVChartContainer :debug="debug" :interval="interval" :signalId="signal_id" :symbol="symbol"></TVChartContainer>
  </div>
</template>

<script>
import TVChartContainer from './components/TVChartContainer'

export default {
  data() {
    return {
      signal_id: null,
      symbol: null,
      interval: null,
      debug: false
    }
  },
  methods: {
    replaceTimeFrame(value) {
      let pData = {
        "1m": 1,
        "3m": 3,
        "5m": 5,
        "15m": 15,
        "30m": 30,
        "1h": 60,
        "2h": 120,
        "4h": 240,
        "6h": 360,
        "8h": 480,
        "12h": 720,
        "1d": 1440,
        "2D": '3D',
        "3D": '3D',
        "W": '1W',
        "w": '1w',
        "1W": '1W',
        "2W": '2W',
        "M": 'M',
        "6M": '6M',
      };
      return pData[value];
    },
    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
          results = regex.exec(location.search);
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
  },
  created() {
    this.signal_id = this.getParameterByName('signal_id');
    this.symbol = this.getParameterByName('symbol') || 'BTCUSDT';
    this.interval = this.getParameterByName('timeframe') || '3m';
    this.interval = this.replaceTimeFrame(this.interval);

    // this.debug = parseInt((this.getParameterByName('debug') || '0')) === 1;

    window.exchange_id = this.getParameterByName('exchange') || "1";
    window.exchange_id = parseInt(window.exchange_id);
    window.has_exchange = window.exchange_id > 0;

  },
  components: {
    TVChartContainer
  }
}
</script>