const sRest = require('./spot/rest')
const sStream = require('./spot/stream')
const fRest = require('./futures/rest')
const fStream = require('./futures/stream')

module.exports = {
    sRest,
    sStream,
    fRest,
    fStream,
    error: err => {
        return (typeof err.response.data != 'undefined') ? `${err.response.data.code}: ${err.response.data.msg}` : err
    }
}
