import api from './binance-api/src/index'
import axios from 'axios'

const intervals = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    '1440': '1d',
    '4320': '3d',
    '10080': '1w'
}
const baseUrlBackApi = 'https://core-api.finomate.io/api';


export const getExchangeServerTime = () => {
    // todo | create endpoint for serverTime
    return okxRequest('https://www.okx.com/api/v5/public/time').then(res => res.data[0].ts)
    // return request('/time').then(res => res.serverTime)
}

export const getSymbols = () => request(baseUrlBackApi + `/exchangeInfo?exchange_id=${window.exchange_id}`).then(res => res.symbols)

export const getKlines = ({symbol, interval, from, to}) => {
    interval = intervals[interval] // set interval

    from *= 1000
    to *= 1000

    if (window.exchange_id === 30) {
        let instId = symbol.toUpperCase().split("USDT")[0];
        instId += "-USDT-SWAP";
        // https://www.okx.com/docs-v5/en/#order-book-trading-market-data-get-candlesticks
        return okxRequest(`https://www.okx.com/api/v5/market/candles`, {
            instId: instId,
            before: from,
            after: to,
            bar: interval,
            limit: 500
        })
            .then(res => {
                res = res.data;
                res = res.reverse();

                if (res && res.length && res[res.length - 1] && res[res.length - 1][0]) {
                    if (localStorage.getItem('last-kline') == null) {
                        localStorage.setItem('last-kline', res[res.length - 1][0]);
                    }
                }


                return res.map(i => ({
                    time: parseInt(i[0]),
                    open: parseFloat(i[1]),
                    high: parseFloat(i[2]),
                    low: parseFloat(i[3]),
                    close: parseFloat(i[4]),
                    volume: parseFloat(i[5]),
                }))
            })

    }

    if (window.exchange_id === 111) {
// https://binance-docs.github.io/apidocs/spot/en/#kline-candlestick-data
        return request('/klines', {symbol: symbol.toUpperCase(), interval, startTime: from, endTime: to})
            .then(res => {
                if (res && res.length && res[res.length - 1] && res[res.length - 1][0]) {
                    if (localStorage.getItem('last-kline') == null) {
                        localStorage.setItem('last-kline', res[res.length - 1][0]);
                    }
                }

                return res.map(i => ({
                    time: parseFloat(i[0]),
                    open: parseFloat(i[1]),
                    high: parseFloat(i[2]),
                    low: parseFloat(i[3]),
                    close: parseFloat(i[4]),
                    volume: parseFloat(i[5]),
                }))
            })
    }

    return request('https://nextapi.finomate.io/api/v1/klines', {
        symbol: symbol.toUpperCase(),
        interval,
        startTime: from,
        endTime: to,
        exchange_code: window.exchange_id,
    })
        .then(res => {
            // res = res.reverse();

            if (res && res.length && res[res.length - 1] && res[res.length - 1][0]) {
                if (localStorage.getItem('last-kline') == null) {
                    localStorage.setItem('last-kline', res[res.length - 1][0]);
                }
            }

            return res.map(i => ({
                time: parseFloat(i[0]),
                open: parseFloat(i[1]),
                high: parseFloat(i[2]),
                low: parseFloat(i[3]),
                close: parseFloat(i[4]),
                volume: parseFloat(i[5]),
            }))
        })
}

export const subscribeKline = ({symbol, interval, uniqueID}, callback) => {
    let exchanges_list = [
        null,
        api.fStream,
        api.sStream,
        api.fStream,
    ];
    interval = intervals[interval] // set interval

    // if (window.exchange_id === 3) {
    //     return null;
    // }

    return exchanges_list[window.exchange_id].kline({symbol, interval, uniqueID}, res => {
        // console.debug(symbol);
        // console.debug(interval);
        // console.debug(uniqueID);
        // console.debug(res);
        // console.debug(callback);
        const candle = formatingKline(res.kline)
        // console.debug(candle);
        localStorage.setItem('last-kline', candle.time);
        callback(candle)
    })
}

export const unsubscribeKline = (uniqueID) => {
    let exchanges_list = [
        null,
        api.fStream,
        api.sStream,
        api.fStream
    ];

    // if (window.exchange_id === 3) {
    //     return null;
    // }

    return exchanges_list[window.exchange_id].close.kline({uniqueID})
}

export const checkInterval = (interval) => !!intervals[interval]

// helpers ------------------------

function formatingKline({openTime, open, high, low, close, volume}) {
    return {
        time: openTime,
        open,
        high,
        low,
        close,
        volume,
    }
}

function request(url, params = {}) {
    let exchanges_list = [
        null,
        'https://fapi.binance.com/fapi/v1/',
        'https://api.binance.com/api/v1/',
        'https://fapi.binance.com/fapi/v1/',
    ];

    let baseURL = url.startsWith("http") ? null : exchanges_list[window.exchange_id];

    return axios({
        baseURL: baseURL,
        method: 'get',
        url,
        params
    })
        .then(res => res.data)
        .catch(res => console.log(res))
}

function okxRequest(url, params = {}) {
    return axios({
        method: 'get',
        url,
        params
    })
        .then(res => res.data)
        .catch(res => console.log(res))
}