<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      order_type: 1,
      tp_type: 1,
      selectin_shape: null,
      selectin_shape_id: null,
      selectin_shape_name: "Trend Line",
    }
  },
  created() {
    // console.debug(window.tvChart.activeChart().selection()._model.selection()._items);
    // console.debug(window.tvChart.activeChart().getShapeById(window.tvChart.activeChart().selection()._model.selection()._items[0].id()));
    this.selectin_shape = window.tvChart.activeChart().getShapeById(window.tvChart.activeChart().selection()._model.selection()._items[0].id());

    this.selectin_shape_name = this.selectin_shape._source.name();
    this.selectin_shape_id = this.selectin_shape._source.id();
  }
}
</script>

<template>
  <Transition name="order-modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
              <h4 class="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-white">
                Dynamic order on {{ selectin_shape_name }}
              </h4>
              <button @click="$emit('close')">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                  <path
                      d="M17.5302 7.53033C17.8231 7.23744 17.8231 6.76256 17.5302 6.46967C17.2373 6.17678 16.7624 6.17678 16.4696 6.46967L11.9999 10.9393L7.53022 6.46967C7.23732 6.17678 6.76245 6.17678 6.46956 6.46967C6.17666 6.76256 6.17666 7.23744 6.46956 7.53033L10.9392 12L6.46955 16.4697C6.17665 16.7626 6.17665 17.2374 6.46955 17.5303C6.76244 17.8232 7.23731 17.8232 7.53021 17.5303L11.9999 13.0607L16.4696 17.5303C16.7625 17.8232 17.2373 17.8232 17.5302 17.5303C17.8231 17.2374 17.8231 16.7626 17.5302 16.4697L13.0605 12L17.5302 7.53033Z"
                      fill="white"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div class="modal-body">
            <div class="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden gap-8">
              <div
                  class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3 p-3 rounded-lg bg-[#242422]"
              >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                  <path
                      opacity="0.4"
                      d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92Z"
                      fill="#ECBF1D"
                  ></path>
                  <path
                      d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z"
                      fill="#ECBF1D"
                  ></path>
                  <path
                      d="M12 18C11.94 18 11.87 17.99 11.8 17.98C11.74 17.97 11.68 17.95 11.62 17.92C11.56 17.9 11.5 17.87 11.44 17.83C11.39 17.79 11.34 17.75 11.29 17.71C11.11 17.52 11 17.26 11 17C11 16.74 11.11 16.48 11.29 16.29C11.34 16.25 11.39 16.21 11.44 16.17C11.5 16.13 11.56 16.1 11.62 16.08C11.68 16.05 11.74 16.03 11.8 16.02C11.93 15.99 12.07 15.99 12.19 16.02C12.26 16.03 12.32 16.05 12.38 16.08C12.44 16.1 12.5 16.13 12.56 16.17C12.61 16.21 12.66 16.25 12.71 16.29C12.89 16.48 13 16.74 13 17C13 17.26 12.89 17.52 12.71 17.71C12.66 17.75 12.61 17.79 12.56 17.83C12.5 17.87 12.44 17.9 12.38 17.92C12.32 17.95 12.26 17.97 12.19 17.98C12.13 17.99 12.06 18 12 18Z"
                      fill="#ECBF1D"
                  ></path>
                </svg>
                <p class="flex-grow-0 flex-shrink-0 w-[364px] text-sm font-medium text-left text-white">
                  All dynamic orders will be set on market. Your order will be opened automatically when the
                  price collides with the selected object.
                </p>
              </div>
              <div class="flex justify-start items-start self-stretch flex-grow overflow-hidden gap-4">
                <div
                    class="flex flex-col justify-start items-start self-stretch flex-grow relative overflow-hidden gap-6"
                >
                  <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                    <div
                        class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-24 relative gap-2 py-1"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#2a2e39]">
                        Order
                      </p>
                    </div>
                    <div class="flex flex-col justify-start items-start flex-grow gap-6">
                      <div
                          class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg border border-[#1a1f2e]"
                      >
                        <div class="flex justify-center items-center flex-grow relative gap-1 p-1 rounded-md"
                             :class="{'bg-[#1a1f2e]' : order_type === 1}">
                          <button @click="order_type = 1"
                                  class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
                                  :class="{'text-white' : order_type === 1,'text-[#2a2e39]' : order_type !== 1}">
                            Buy order
                          </button>
                        </div>
                        <div class="flex justify-center items-center flex-grow relative gap-1 p-1 rounded-md"
                             :class="{'bg-[#1a1f2e]' : order_type === 2}">
                          <button @click="order_type = 2"
                                  class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
                                  :class="{'text-white' : order_type === 2,'text-[#2a2e39]' : order_type !== 2}">
                            Sell order
                          </button>
                        </div>
                      </div>
                      <div
                          class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-5"
                      >
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                          <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#35415f]">
                            Available:
                          </p>
                          <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">
                            25000.0 USDT
                          </p>
                          <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow-0 flex-shrink-0"
                              preserveAspectRatio="xMidYMid meet"
                          >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16ZM7.5 4V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5H8.5V4H7.5Z"
                                fill="#2962FF"
                            ></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 h-10 px-3 rounded bg-[#1a1f2e]"
                        >
                          <div
                              class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1.5"
                          >
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
                              Amount
                            </p>
                            <div class="self-stretch flex-grow-0 flex-shrink-0 w-px bg-[#131722]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                              Total
                            </p>
                          </div>
                          <div
                              class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1.5"
                          >
                            <input type="text" inputmode="decimal" pattern="^[0-9]*[.,]?[0-9]+$"
                                   class="w-full h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                                   value="1.0">
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                              BTC
                            </p>
                          </div>
                        </div>

                        <div class="flex-grow-0 flex-shrink-0 w-[296px] h-10">
                          <div
                              class="flex justify-between items-center w-[296px] h-0.5 absolute left-0 top-[103px] bg-[#35415f]"
                          >
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle
                                  cx="5"
                                  cy="5"
                                  r="4"
                                  fill="#35415F"
                                  stroke="#35415F"
                                  stroke-width="2"
                              ></circle>
                            </svg
                            >
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle
                                  cx="5"
                                  cy="5"
                                  r="4"
                                  fill="#35415F"
                                  stroke="#35415F"
                                  stroke-width="2"
                              ></circle>
                            </svg
                            >
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle
                                  cx="5"
                                  cy="5"
                                  r="4"
                                  fill="#35415F"
                                  stroke="#35415F"
                                  stroke-width="2"
                              ></circle>
                            </svg
                            >
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle
                                  cx="5"
                                  cy="5"
                                  r="4"
                                  fill="#131722"
                                  stroke="#35415F"
                                  stroke-width="2"
                              ></circle>
                            </svg
                            >
                            <svg
                                width="9"
                                height="10"
                                viewBox="0 0 9 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle
                                  cx="5"
                                  cy="5"
                                  r="4"
                                  fill="#131722"
                                  stroke="#35415F"
                                  stroke-width="2"
                              ></circle>
                            </svg>
                          </div>
                          <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="absolute left-[172.05px] top-[99px]"
                              preserveAspectRatio="xMidYMid meet"
                          >
                            <g filter="url(#filter0_d_2636_88234)">
                              <ellipse cx="6.84089" cy="7" rx="3.79487" ry="4" fill="white"></ellipse>
                              <ellipse
                                  cx="6.84089"
                                  cy="7"
                                  rx="3.79487"
                                  ry="4"
                                  stroke="#2962FF"
                                  stroke-width="2"
                              ></ellipse>
                            </g>
                            <defs>
                              <filter
                                  id="filter0_d_2636_88234"
                                  x="0.0460205"
                                  y="0"
                                  width="13.5897"
                                  height="14"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                              >
                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                ></feColorMatrix>
                                <feMorphology
                                    radius="2"
                                    operator="dilate"
                                    in="SourceAlpha"
                                    result="effect1_dropShadow_2636_88234"
                                ></feMorphology>
                                <feOffset></feOffset>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0745098 0 0 0 0 0.0901961 0 0 0 0 0.133333 0 0 0 1 0"
                                ></feColorMatrix>
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_2636_88234"
                                ></feBlend>
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_2636_88234"
                                    result="shape"
                                ></feBlend>
                              </filter>
                            </defs>
                          </svg>
                          <div
                              class="flex justify-center items-center w-[37.95px] h-6 absolute left-[158.44px] top-[116px] gap-2 rounded bg-[#2962ff]"
                          >
                            <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">
                              64%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="self-stretch flex-grow-0 flex-shrink-0 h-px bg-[#1a1f2e]"></div>
                  <div
                      class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-8"
                  >
                    <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                      <div
                          class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-24 relative gap-2"
                      >
                        <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#2a2e39]">
                          Target Point
                        </p>
                      </div>
                      <div class="flex flex-col justify-start items-start flex-grow gap-4">
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                          <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                              preserveAspectRatio="xMidYMid meet"
                          >
                            <rect width="16" height="16" rx="8" fill="#2962FF"></rect>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.1872 5.76004C11.3823 5.95547 11.382 6.27206 11.1866 6.46715L7.40659 10.2405C7.21128 10.4354 6.89494 10.4353 6.6998 10.2402L4.81313 8.3535C4.61787 8.15824 4.61787 7.84166 4.81313 7.64639C5.0084 7.45113 5.32498 7.45113 5.52024 7.64639L7.05367 9.17982L10.4801 5.75942C10.6755 5.56433 10.9921 5.56461 11.1872 5.76004Z"
                                fill="#131722"
                            ></path>
                          </svg>
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Set TP</p>
                        </div>
                        <div
                            class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-1 rounded-lg border border-[#1a1f2e]"
                        >
                          <div class="flex justify-center items-center flex-grow relative gap-1 p-1 rounded-md"
                               :class="{'bg-[#1a1f2e]' : tp_type === 1}">
                            <button @click="tp_type = 1" class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
                                    :class="{'text-white' : tp_type === 1,'text-[#2a2e39]' : tp_type !== 1}">
                              Value
                            </button>
                          </div>
                          <div class="flex justify-center items-center flex-grow relative gap-1 p-1 rounded-md"
                               :class="{'bg-[#1a1f2e]' : tp_type === 2}">
                            <button @click="tp_type = 2" class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
                                    :class="{'text-white' : tp_type === 2,'text-[#2a2e39]' : tp_type !== 2}">
                              Percent
                            </button>
                          </div>
                          <div class="flex justify-center items-center flex-grow relative gap-1 p-1 rounded-md"
                               :class="{'bg-[#1a1f2e]' : tp_type === 3}">
                            <button @click="tp_type = 3"
                                    class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#2a2e39]"
                                    :class="{'text-white' : tp_type === 3,'text-[#2a2e39]' : tp_type !== 3}">
                              Object
                            </button>
                          </div>
                        </div>
                        <div
                            class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3"
                        >
                          <div v-if="tp_type === 1"
                               class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 h-10 relative px-3 rounded bg-[#1a1f2e]"
                          >
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                              Value
                            </p>
                            <div
                                class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1.5"
                            >
                              <input type="text" inputmode="decimal" pattern="^[0-9]*[.,]?[0-9]+$"
                                     class="w-full h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                                     value="1.0">
                              <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                                USDT
                              </p>
                            </div>
                          </div>
                          <div v-if="tp_type === 2"
                               class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 h-10 relative px-3 rounded bg-[#1a1f2e]">
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                              TP
                            </p>
                            <div
                                class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1.5"
                            >
                              <input type="text" inputmode="decimal" pattern="^[0-9]*[.,]?[0-9]+$"
                                     class="w-full h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                                     value="1.0">
                              <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">
                                % of Ep
                              </p>
                            </div>
                          </div>
                          <div v-if="tp_type === 3"
                               class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
                            <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#35415f]">
                              Trending line is set as TP
                            </p>
                            <button type="button"
                                    class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#2962ff]">
                              See on chart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                      <div
                          class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-24 relative gap-2"
                      >
                        <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#2a2e39]">
                          Stop Loss
                        </p>
                      </div>
                      <div class="flex flex-col justify-start items-start flex-grow gap-3">
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                          <div
                              class="flex-grow-0 flex-shrink-0 w-4 h-4 relative rounded-xl border border-[#1a1f2e]"
                          ></div>
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Set SL</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                      <div
                          class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-24 h-7 gap-2 py-1"
                      ></div>
                      <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                        <div class="flex flex-col justify-start items-start flex-grow gap-3">
                          <a href="javascript:;"
                             class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08173 12.4183 0.5 8 0.5C3.58173 0.5 0 4.08173 0 8.5C0 12.9183 3.58173 16.5 8 16.5ZM7.5 4.5V8H4V9H7.5V12.5H8.5V9H12V8H8.5V4.5H7.5Z"
                                  fill="#2962FF"
                              ></path>
                            </svg>
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#2962ff]">
                              New TP and SL
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="self-stretch flex-grow-0 flex-shrink-0 h-px bg-[#1a1f2e]"></div>

                  <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                    <div
                        class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-24 relative gap-2 py-1"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#2a2e39]">Expiration</p>
                      <p class="flex-grow-0 flex-shrink-0 w-16 text-xs text-left text-white">
                  <span class="flex-grow-0 flex-shrink-0 w-16 text-xs text-left text-white">2021-12-23</span
                  ><br/><span class="flex-grow-0 flex-shrink-0 w-16 text-xs text-left text-white">16:25</span>
                      </p>
                    </div>
                    <div class="flex flex-col justify-start items-start flex-grow gap-6">
                      <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                        <svg
                            width="42"
                            height="25"
                            viewBox="0 0 42 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex-grow-0 flex-shrink-0 w-[42px] h-6 relative"
                            preserveAspectRatio="none"
                        >
                          <rect y="0.5" width="42" height="24" rx="12" fill="#2A2E39"></rect>
                          <circle cx="12" cy="12.5" r="8" fill="#131722"></circle>
                        </svg>
                        <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
                          Open-ended (No expiration)
                        </p>
                      </div>
                      <div
                          class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3 p-3 rounded-lg border border-[#1a1f2e]"
                      >
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-4">
                          <svg
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow-0 flex-shrink-0"
                              preserveAspectRatio="xMidYMid meet"
                          >
                            <path d="M5.5 1L1.5 5.5L5.5 10" stroke="#282F41"></path>
                          </svg>
                          <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">
                            December 2023
                          </p>
                          <svg
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow-0 flex-shrink-0"
                              preserveAspectRatio="xMidYMid meet"
                          >
                            <path d="M0.5 10L4.5 5.5L0.5 1" stroke="white"></path>
                          </svg>
                        </div>
                        <div
                            class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1"
                        >
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3 rounded bg-[#1a1f2e]"
                          >
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Mo</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Tu</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">We</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Th</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Fr</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Sa</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">Su</p>
                          </div>
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3"
                          >
                            <p class="flex-grow w-[28.57px] opacity-0 text-xs font-medium text-center text-[#2a2e39]">
                              5
                            </p>
                            <p class="flex-grow w-[28.57px] opacity-0 text-xs font-medium text-center text-[#2a2e39]">
                              6
                            </p>
                            <p class="flex-grow w-[28.57px] opacity-0 text-xs font-medium text-center text-[#2a2e39]">
                              7
                            </p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">1</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">2</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">3</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">4</p>
                          </div>
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3"
                          >
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">5</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">6</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">7</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">8</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">9</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">10</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">11</p>
                          </div>
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3"
                          >
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">12</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">13</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">14</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-[#2a2e39]">15</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">16</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">17</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">18</p>
                          </div>
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3"
                          >
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">19</p>
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">20</p>
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">21</p>
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">22</p>
                            <div class="flex-grow-0 flex-shrink-0 w-8 h-8 relative rounded bg-[#2962ff]">
                              <p class="w-8 absolute left-0 top-1.5 text-xs font-medium text-center text-white">23</p>
                            </div>
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">24</p>
                            <p class="flex-grow w-7 text-xs font-medium text-center text-white">25</p>
                          </div>
                          <div
                              class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-8 relative gap-3"
                          >
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">26</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">27</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">28</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">29</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">30</p>
                            <p class="flex-grow w-[28.57px] text-xs font-medium text-center text-white">31</p>
                            <p class="flex-grow w-[28.57px] opacity-0 text-xs font-medium text-center text-white">
                              7
                            </p>
                          </div>
                        </div>
                        <div class="self-stretch flex-grow-0 flex-shrink-0 h-px bg-[#1a1f2e]"></div>
                        <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">Time</p>
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                          <div
                              class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-12 h-10 relative gap-3 px-3 rounded bg-[#1a1f2e]"
                          >
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">16</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">:</p>
                          <div
                              class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-12 h-10 relative gap-3 px-3 rounded bg-[#1a1f2e]"
                          >
                            <p class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">25</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-4">
              <div
                  class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-10 relative gap-[193px] px-6 rounded bg-[#1a1f2e]"
              >
                <button @click="$emit('close')"
                        class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">Cancel
                </button>
              </div>
              <div
                  class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-10 relative gap-[193px] px-6 rounded bg-[#2962ff]"
              >
                <button @click="$emit('close')"
                        class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">Set order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #131722;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 480px;
}

@media (max-width: 450px) {
  .modal-container {
    max-width: 90vw;
  }

}

.modal-header h3, .modal-header .title {
  color: #b2b5be;
  font-size: 20px;
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
