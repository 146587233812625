<template>
  <div class="overflow-auto overflow-x-hidden"
       style="position: fixed; min-height: 40vh;height: 47vh; background: rgb(19, 23, 34); top: 520px; width: calc(-420px + 100vw) !important; z-index: 1000;border-top: 1px solid #30333e;">
    <div
        class="flex flex-col justify-start items-start gap-6 p-6 bg-[#131722] "
    >
      <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
        <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">Open Orders</p>
        <button class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#2962ff]" @click="$emit('seeAll')">
          See all orders
        </button>
      </div>

      <div
          class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6"
      >
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg w-full">
          <table class="w-full text-sm text-left">
            <thead class="text-xs text-[#2A2E39] uppercase">
            <tr class="border-b border-[#2A2E39] text-gray-500">
              <th scope="col" class="py-3 px-6">
                Date
              </th>
              <th scope="col" class="py-3 px-6">
                Pair
              </th>
              <th scope="col" class="py-3 px-6">
                Type
              </th>
              <th scope="col" class="py-3 px-6">
                Side
              </th>
              <th scope="col" class="py-3 px-6">
                Price
              </th>
              <th scope="col" class="py-3 px-6">
                Amount
              </th>
              <th scope="col" class="py-3 px-6">
                Filled
              </th>
              <th scope="col" class="py-3 px-6">
                Total
              </th>
              <th scope="col" class="py-3 px-6">
                Trigger Conditions
              </th>
              <th scope="col" class="py-3 px-6">

              </th>
            </tr>
            </thead>
            <tbody>
            <!-- Repeat this tr for each row -->
            <tr v-for="row in randomRows" class="border-b border-[#2A2E39]">
              <td scope="row" class="py-4 px-6 font-medium whitespace-nowrap text-white">
                {{ row.date }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.symbol }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.marker }}
              </td>
              <td class="py-4 px-6" :class="{'text-[#26A69A]' : row.action === 'Buy','text-[#EF5350]' : row.action === 'Sell',}">
                {{ row.action }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.price }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.quantity }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.percentage }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.profit }}
              </td>
              <td class="py-4 px-6 text-white">
                {{ row.condition }}
              </td>
              <td class="py-4 px-6">
                <button class="flex justify-end items-center flex-grow relative">
                  <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                      preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.57143 2H13.4286V3.14286H18V4.28571H15.7143V18H4.28571V4.28571H2V3.14286H6.57143V2ZM8.85714 6.57143H7.71429V15.7143H8.85714V6.57143ZM11.1429 6.57143H12.2857V15.7143H11.1429V6.57143Z"
                        fill="#2962FF"
                    ></path>
                  </svg>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "ordersTable",
  data() {
    return {
      randomRows: []
    }
  },
  methods: {
    generateRandomRows() {
      const numberOfRows = 12;

      const dates = ["2023-12-01 22:52", "2023-11-02 15:30", "2023-12-03 09:45"];
      const symbols = ["BTC/USDT", "ETH/USDT", "XRP/USDT"];
      const markers = ["Limit marker", "Market marker", "Stop marker"];
      const actions = ["Buy", "Sell"];
      const prices = [280.0, 300.0, 260.0];
      const quantities = [0.050, 0.030, 0.070];
      const percentages = ["0.00%", "1.50%", "-0.75%"];
      const profits = ["14.0USDT", "20.0USDT", "-5.0USDT"];
      const conditions = ["Mark price >= 810.0", "Mark price <= 750.0", "Volume > 100"];

      this.randomRows = Array.from({length: numberOfRows}, (_, index) => {
        return {
          id: index,
          date: dates[Math.floor(Math.random() * dates.length)],
          symbol: symbols[Math.floor(Math.random() * symbols.length)],
          marker: markers[Math.floor(Math.random() * markers.length)],
          action: actions[Math.floor(Math.random() * actions.length)],
          price: prices[Math.floor(Math.random() * prices.length)],
          quantity: quantities[Math.floor(Math.random() * quantities.length)],
          percentage: percentages[Math.floor(Math.random() * percentages.length)],
          profit: profits[Math.floor(Math.random() * profits.length)],
          condition: conditions[Math.floor(Math.random() * conditions.length)],
        };
      });
    }
  },
  mounted() {
    this.generateRandomRows();
  }
}
</script>

<style scoped>

</style>