<template>
  <div class="relative">
    <input :id="this.sliderId" type="range" min="0" max="100"
           class="relative opacity-0 w-full cursor-pointer block top-[10px]"
           style="z-index: 100"
           :value="value"
           @input="updateSliderLabel"/>
    <!-- Slider Track -->
    <div
        class="slider-track flex justify-between items-center h-0.5 bg-[#35415f]">
      <!-- Slider Circles (Static) -->
      <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0"
          preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="5" cy="5" r="4" fill="#131722" stroke="#35415F" stroke-width="2"></circle>
      </svg
      >
      <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0"
          preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="5" cy="5" r="4" fill="#131722" stroke="#35415F" stroke-width="2"></circle>
      </svg
      >
      <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0"
          preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="5" cy="5" r="4" fill="#131722" stroke="#35415F" stroke-width="2"></circle>
      </svg
      >
      <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0"
          preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="5" cy="5" r="4" fill="#131722" stroke="#35415F" stroke-width="2"></circle>
      </svg
      >
      <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0"
          preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="5" cy="5" r="4" fill="#131722" stroke="#35415F" stroke-width="2"></circle>
      </svg>
    </div>

    <!-- Slider Value Label -->
    <div :id="this.sliderValueId"
         class="flex justify-center items-center w-10 h-6 gap-2 rounded bg-[#2962FF] absolute"
         style="margin-top: 10px;z-index: 5">
      <a href="javascript:;">
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-[0.9px] top-[-18.5px]"
            preserveAspectRatio="xMidYMid meet"
        >
          <g filter="url(#filter0_d_3897_2435)">
            <circle cx="7.40002" cy="7" r="4" fill="white"></circle>
            <circle cx="7.40002" cy="7" r="4" stroke="#2962FF" stroke-width="2"></circle>
          </g>
          <defs>
            <filter
                x="0.400024"
                y="0"
                width="14"
                height="14"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
              ></feColorMatrix>
              <feMorphology
                  radius="2"
                  operator="dilate"
                  in="SourceAlpha"
                  result="effect1_dropShadow_3897_2435"
              ></feMorphology>
              <feOffset></feOffset>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0745098 0 0 0 0 0.0901961 0 0 0 0 0.133333 0 0 0 1 0"
              ></feColorMatrix>
              <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_3897_2435"
              ></feBlend>
              <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3897_2435"
                  result="shape"
              ></feBlend>
            </filter>
          </defs>
        </svg>
      </a>
      <p class="text-xs font-medium text-left text-white">{{ value }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "myRangeSlider",
  props: ['value'],
  data() {
    return {
      sliderId : 'sid',
      sliderValueId : 'svid'
    }
  },
  methods: {
    generateRandomCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomCode = '';

      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomCode += characters.charAt(randomIndex);
      }

      return randomCode;
    },
    updateSliderLabel(event) {
      let tempValue = this.$props.value;
      if (event !== null) {
        tempValue = event.target.value;
      }

      let value = tempValue;
      // Update the position of the slider thumb (circle) and label
      const slider = document.getElementById(this.sliderId);

      // Calculate the new position for the slider label
      const trackWidth = document.querySelector('.slider-track').offsetWidth;
      const thumbWidth = 15; // Approximate width of the thumb for offset calculation
      const max = slider.max;
      const min = slider.min;
      let leftValue = ((value - min) / (max - min)) * (trackWidth - thumbWidth);


      if (value >= 0) {
        leftValue -= 3;
      }
      if (value >= 20) {
        leftValue += 2;
      }
      if (value >= 70) {
        leftValue += 3;
      }

      // Update the position of the slider label
      document.getElementById(this.sliderValueId).style.left = `${leftValue}px`;

      this.$emit('input', parseInt(value));
    },
  },
  created() {
    this.sliderId = this.generateRandomCode();
    this.sliderValueId = this.generateRandomCode();
    console.clear();
  },
  mounted() {
    this.updateSliderLabel(null);
  }
}
</script>

<style scoped>

</style>