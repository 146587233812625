<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {}
  },
  created() {
  }
}
</script>

<template>
  <Transition name="analyz-modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-b">
          <div class="modal-header">
            <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
              <h4 class="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-white">
                Spot Profit and Lost Analysis
              </h4>
              <button @click="$emit('close')">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                  <path
                      d="M17.5302 7.53033C17.8231 7.23744 17.8231 6.76256 17.5302 6.46967C17.2373 6.17678 16.7624 6.17678 16.4696 6.46967L11.9999 10.9393L7.53022 6.46967C7.23732 6.17678 6.76245 6.17678 6.46956 6.46967C6.17666 6.76256 6.17666 7.23744 6.46956 7.53033L10.9392 12L6.46955 16.4697C6.17665 16.7626 6.17665 17.2374 6.46955 17.5303C6.76244 17.8232 7.23731 17.8232 7.53021 17.5303L11.9999 13.0607L16.4696 17.5303C16.7625 17.8232 17.2373 17.8232 17.5302 17.5303C17.8231 17.2374 17.8231 16.7626 17.5302 16.4697L13.0605 12L17.5302 7.53033Z"
                      fill="white"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div class="modal-body-b">
            <div
                class="flex flex-col justify-start items-start w-[1280px] overflow-hidden gap-6 p-6 rounded-lg bg-[#131722]"
            >
              <div
                  class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6 p-6 rounded-lg border border-[#1a1f2e]"
              >
                <div
                    class="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 w-[560px] relative gap-2 rounded-lg"
                >
                  <p class="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#5b647b]">
                    Estimated Balance
                  </p>
                  <div class="justify-start items-baseline gap-1 inline-flex">
                    <div class="text-white text-[28px] font-semibold font-['Poppins'] leading-10">0.16222058</div>
                    <div class="text-slate-600 text-base font-normal font-['Poppins'] leading-normal">BTC = $7.356.37
                    </div>
                  </div>

                </div>
                <div class="flex flex-col justify-center items-start flex-grow gap-2 rounded-lg">
                  <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                    <p class="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#5b647b]">
                      Yesterday’s PNL
                    </p>
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                          d="M8.00004 15.6663C4.04671 15.6663 0.833374 12.453 0.833374 8.49967C0.833374 4.54634 4.04671 1.33301 8.00004 1.33301C11.9534 1.33301 15.1667 4.54634 15.1667 8.49967C15.1667 12.453 11.9534 15.6663 8.00004 15.6663ZM8.00004 2.33301C4.60004 2.33301 1.83337 5.09967 1.83337 8.49967C1.83337 11.8997 4.60004 14.6663 8.00004 14.6663C11.4 14.6663 14.1667 11.8997 14.1667 8.49967C14.1667 5.09967 11.4 2.33301 8.00004 2.33301Z"
                          fill="#5B647B"
                      ></path>
                      <path
                          d="M8 9.66634C7.72667 9.66634 7.5 9.43967 7.5 9.16634V5.83301C7.5 5.55967 7.72667 5.33301 8 5.33301C8.27333 5.33301 8.5 5.55967 8.5 5.83301V9.16634C8.5 9.43967 8.27333 9.66634 8 9.66634Z"
                          fill="#5B647B"
                      ></path>
                      <path
                          d="M8.00004 11.8329C7.91337 11.8329 7.82671 11.8129 7.74671 11.7796C7.66671 11.7463 7.59337 11.6996 7.52671 11.6396C7.46671 11.5729 7.42004 11.5063 7.38671 11.4196C7.35337 11.3396 7.33337 11.2529 7.33337 11.1663C7.33337 11.0796 7.35337 10.9929 7.38671 10.9129C7.42004 10.8329 7.46671 10.7596 7.52671 10.6929C7.59337 10.6329 7.66671 10.5863 7.74671 10.5529C7.90671 10.4863 8.09337 10.4863 8.25337 10.5529C8.33337 10.5863 8.40671 10.6329 8.47337 10.6929C8.53337 10.7596 8.58004 10.8329 8.61337 10.9129C8.64671 10.9929 8.66671 11.0796 8.66671 11.1663C8.66671 11.2529 8.64671 11.3396 8.61337 11.4196C8.58004 11.5063 8.53337 11.5729 8.47337 11.6396C8.40671 11.6996 8.33337 11.7463 8.25337 11.7796C8.17337 11.8129 8.08671 11.8329 8.00004 11.8329Z"
                          fill="#5B647B"
                      ></path>
                    </svg>
                  </div>
                  <div class="justify-start items-baseline gap-1 inline-flex">
                    <div class="text-white text-[28px] font-semibold font-['Poppins'] leading-10">-$58.21</div>
                    <div class="text-red-500 text-base font-normal font-['Poppins'] leading-normal">-0.79%</div>
                  </div>
                </div>
                <div class="flex flex-col justify-center items-start flex-grow relative gap-2 rounded-lg">
                  <p class="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#5b647b]">
                    30 days PNL
                  </p>
                  <div class="justify-start items-baseline gap-1 inline-flex">
                    <div class="text-white text-[28px] font-semibold font-['Poppins'] leading-10">+$436.97</div>
                    <div class="text-teal-500 text-base font-normal font-['Poppins'] leading-normal">+11.35%</div>
                  </div>

                </div>
              </div>
              <div
                  class="flex justify-start items-start flex-grow-0 flex-shrink-0 p-1 rounded-lg border border-[#1a1f2e]"
              >
                <div
                    class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-4 py-1 rounded-md bg-[#1a1f2e]"
                >
                  <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">Last 7 days</p>
                </div>
                <div
                    class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-4 py-1 rounded-md"
                >
                  <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#2a2e39]">
                    Last 30 days
                  </p>
                </div>
                <div
                    class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-4 py-1 rounded-md"
                >
                  <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#2a2e39]">
                    Customize
                  </p>
                </div>
              </div>
              <div class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6">
                <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[720px] gap-6">
                  <div
                      class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4 p-4 rounded-lg border border-[#1a1f2e]"
                  >
                    <div
                        class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                        Cumulative PNL (%)
                      </p>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M8.00001 15.1663C4.04668 15.1663 0.833344 11.953 0.833344 7.99967C0.833344 4.04634 4.04668 0.833008 8.00001 0.833008C11.9533 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9533 15.1663 8.00001 15.1663ZM8.00001 1.83301C4.60001 1.83301 1.83334 4.59967 1.83334 7.99967C1.83334 11.3997 4.60001 14.1663 8.00001 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00001 1.83301Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8 9.16634C7.72667 9.16634 7.5 8.93967 7.5 8.66634V5.33301C7.5 5.05967 7.72667 4.83301 8 4.83301C8.27333 4.83301 8.5 5.05967 8.5 5.33301V8.66634C8.5 8.93967 8.27333 9.16634 8 9.16634Z"
                            fill="white"
                        ></path>
                        <path
                            d="M7.99998 11.3329C7.91331 11.3329 7.82665 11.3129 7.74665 11.2796C7.66665 11.2463 7.59331 11.1996 7.52665 11.1396C7.46665 11.0729 7.41998 11.0063 7.38665 10.9196C7.35331 10.8396 7.33331 10.7529 7.33331 10.6663C7.33331 10.5796 7.35331 10.4929 7.38665 10.4129C7.41998 10.3329 7.46665 10.2596 7.52665 10.1929C7.59331 10.1329 7.66665 10.0863 7.74665 10.0529C7.90665 9.98626 8.09331 9.98626 8.25331 10.0529C8.33331 10.0863 8.40665 10.1329 8.47331 10.1929C8.53331 10.2596 8.57998 10.3329 8.61331 10.4129C8.64665 10.4929 8.66665 10.5796 8.66665 10.6663C8.66665 10.7529 8.64665 10.8396 8.61331 10.9196C8.57998 11.0063 8.53331 11.0729 8.47331 11.1396C8.40665 11.1996 8.33331 11.2463 8.25331 11.2796C8.17331 11.3129 8.08665 11.3329 7.99998 11.3329Z"
                            fill="white"
                        ></path>
                      </svg>
                      <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-[#26a69a]">+2.2%</p>
                      <div class="flex justify-end items-center flex-grow gap-4">
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                          <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
                            <svg
                                width="6"
                                height="2"
                                viewBox="0 0 6 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                              <path d="M0 1H6" stroke="#2962FF"></path>
                            </svg
                            >
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle cx="4" cy="4" r="3.5" stroke="#2962FF"></circle>
                            </svg
                            >
                            <svg
                                width="6"
                                height="2"
                                viewBox="0 0 6 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                              <path d="M0 1H6" stroke="#2962FF"></path>
                            </svg>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#5b647b]">
                            Cumulative PNL (%)
                          </p>
                        </div>
                        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                          <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
                            <svg
                                width="6"
                                height="2"
                                viewBox="0 0 6 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                              <path d="M0 1H6" stroke="#002999"></path>
                            </svg
                            >
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="xMidYMid meet"
                            >
                              <circle cx="4" cy="4" r="3.5" stroke="#002999"></circle>
                            </svg
                            >
                            <svg
                                width="6"
                                height="2"
                                viewBox="0 0 6 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                              <path d="M0 1H6" stroke="#002999"></path>
                            </svg>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#5b647b]">
                            Cumulative BTC Trend
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="self-stretch flex-grow-0 flex-shrink-0 h-32 relative overflow-hidden">
                      <div
                          class="flex flex-col justify-between items-center w-[688px] h-[104px] absolute left-0 top-0"
                      >
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex justify-start items-start w-[640px] absolute left-12 top-28 px-2">
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-08</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-09</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-10</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-11</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-12</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-13</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-14</p>
                        </div>
                      </div>
                      <svg
                          style="z-index: 10000000000000;position: inherit;"
                          width="540"
                          height="52"
                          viewBox="0 0 540 52"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-[540px] h-[52px]"
                          preserveAspectRatio="none"
                      >
                        <path d="M3 46L92 33L181 13L270 43L359 49L448 36L537 3" stroke="#2962FF"></path>
                        <circle cx="3" cy="46" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="92" cy="33" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="181" cy="13" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="270" cy="43" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="359" cy="49" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="448" cy="36" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="537" cy="3" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                      </svg
                      >
                      <svg
                          width="540"
                          height="52"
                          viewBox="0 0 540 52"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-[540px] h-[52px]"
                          preserveAspectRatio="none"
                      >
                        <path d="M3 46L92 25L181 29L270 35L359 49L448 28L537 3" stroke="#002999"></path>
                        <circle cx="3" cy="46" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="92" cy="25" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="181" cy="29" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="270" cy="35" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="359" cy="49" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="448" cy="28" r="2.5" fill="#131722" stroke="#002999"></circle>
                        <circle cx="537" cy="3" r="2.5" fill="#131722" stroke="#002999"></circle>
                      </svg>
                    </div>

                  </div>

                  <div
                      class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4 p-4 rounded-lg border border-[#1a1f2e]"
                  >
                    <div
                        class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                        Daily PNL
                      </p>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M8.00001 15.1663C4.04668 15.1663 0.833344 11.953 0.833344 7.99967C0.833344 4.04634 4.04668 0.833008 8.00001 0.833008C11.9533 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9533 15.1663 8.00001 15.1663ZM8.00001 1.83301C4.60001 1.83301 1.83334 4.59967 1.83334 7.99967C1.83334 11.3997 4.60001 14.1663 8.00001 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00001 1.83301Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8 9.16634C7.72667 9.16634 7.5 8.93967 7.5 8.66634V5.33301C7.5 5.05967 7.72667 4.83301 8 4.83301C8.27333 4.83301 8.5 5.05967 8.5 5.33301V8.66634C8.5 8.93967 8.27333 9.16634 8 9.16634Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8.00001 11.3329C7.91334 11.3329 7.82668 11.3129 7.74668 11.2796C7.66668 11.2463 7.59334 11.1996 7.52668 11.1396C7.46668 11.0729 7.42001 11.0063 7.38668 10.9196C7.35334 10.8396 7.33334 10.7529 7.33334 10.6663C7.33334 10.5796 7.35334 10.4929 7.38668 10.4129C7.42001 10.3329 7.46668 10.2596 7.52668 10.1929C7.59334 10.1329 7.66668 10.0863 7.74668 10.0529C7.90668 9.98626 8.09334 9.98626 8.25334 10.0529C8.33334 10.0863 8.40668 10.1329 8.47334 10.1929C8.53334 10.2596 8.58001 10.3329 8.61334 10.4129C8.64668 10.4929 8.66668 10.5796 8.66668 10.6663C8.66668 10.7529 8.64668 10.8396 8.61334 10.9196C8.58001 11.0063 8.53334 11.0729 8.47334 11.1396C8.40668 11.1996 8.33334 11.2463 8.25334 11.2796C8.17334 11.3129 8.08668 11.3329 8.00001 11.3329Z"
                            fill="white"
                        ></path>
                      </svg>
                      <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-[#ef5350]">-$58.21</p>
                    </div>
                    <div class="self-stretch flex-grow-0 flex-shrink-0 h-32 relative">
                      <div
                          class="flex flex-col justify-between items-center w-[688px] h-[104px] absolute left-0 top-0"
                      >
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="629"
                              height="2"
                              viewBox="0 0 629 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="629"
                              height="2"
                              viewBox="0 0 629 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="629"
                              height="2"
                              viewBox="0 0 629 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="629"
                              height="2"
                              viewBox="0 0 629 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="629"
                              height="2"
                              viewBox="0 0 629 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex justify-start items-start w-[640px] absolute left-12 top-28 px-2">
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-08</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-09</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-10</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-11</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-12</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-13</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-14</p>
                        </div>
                      </div>
                      <div class="w-[627px] h-[104px] absolute left-[61px] top-0">
                        <div class="w-6 h-2.5 absolute left-8 top-[61px] rounded bg-[#ef5350]"></div>
                        <div
                            class="w-6 h-[22px] absolute left-[121.24px] top-[52px] rounded bg-[#26a69a]"
                        ></div>
                        <div
                            class="w-6 h-[38px] absolute left-[210.81px] top-[51px] rounded bg-[#ef5350]"
                        ></div>
                        <div
                            class="w-6 h-[34px] absolute left-[300.38px] top-[18px] rounded bg-[#26a69a]"
                        ></div>
                        <div
                            class="flex flex-col justify-start items-center w-[72px] h-1 absolute left-[277px] top-[-26px]"
                        ></div>
                        <div class="w-6 h-[41px] absolute left-[390px] top-[11px] rounded bg-[#26a69a]"></div>
                        <div class="w-6 h-px absolute left-[480px] top-[51px] rounded bg-[#ef5350]"></div>
                        <div class="w-6 h-[15px] absolute left-[569px] top-[51px] rounded bg-[#ef5350]"></div>
                      </div>
                    </div>
                  </div>
                  <div
                      class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4 p-4 rounded-lg border border-[#1a1f2e]"
                  >
                    <div
                        class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                        Profits
                      </p>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M8.00001 15.1663C4.04668 15.1663 0.833344 11.953 0.833344 7.99967C0.833344 4.04634 4.04668 0.833008 8.00001 0.833008C11.9533 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9533 15.1663 8.00001 15.1663ZM8.00001 1.83301C4.60001 1.83301 1.83334 4.59967 1.83334 7.99967C1.83334 11.3997 4.60001 14.1663 8.00001 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00001 1.83301Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8 9.16634C7.72667 9.16634 7.5 8.93967 7.5 8.66634V5.33301C7.5 5.05967 7.72667 4.83301 8 4.83301C8.27333 4.83301 8.5 5.05967 8.5 5.33301V8.66634C8.5 8.93967 8.27333 9.16634 8 9.16634Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8.00001 11.3329C7.91334 11.3329 7.82668 11.3129 7.74668 11.2796C7.66668 11.2463 7.59334 11.1996 7.52668 11.1396C7.46668 11.0729 7.42001 11.0063 7.38668 10.9196C7.35334 10.8396 7.33334 10.7529 7.33334 10.6663C7.33334 10.5796 7.35334 10.4929 7.38668 10.4129C7.42001 10.3329 7.46668 10.2596 7.52668 10.1929C7.59334 10.1329 7.66668 10.0863 7.74668 10.0529C7.90668 9.98626 8.09334 9.98626 8.25334 10.0529C8.33334 10.0863 8.40668 10.1329 8.47334 10.1929C8.53334 10.2596 8.58001 10.3329 8.61334 10.4129C8.64668 10.4929 8.66668 10.5796 8.66668 10.6663C8.66668 10.7529 8.64668 10.8396 8.61334 10.9196C8.58001 11.0063 8.53334 11.0729 8.47334 11.1396C8.40668 11.1996 8.33334 11.2463 8.25334 11.2796C8.17334 11.3129 8.08668 11.3329 8.00001 11.3329Z"
                            fill="white"
                        ></path>
                      </svg>
                      <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-[#26a69a]">+$104.62</p>
                    </div>
                    <div class="self-stretch flex-grow-0 flex-shrink-0 h-32 relative overflow-hidden">
                      <div
                          class="flex flex-col justify-between items-center w-[688px] h-[104px] absolute left-0 top-0"
                      >
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1.33301H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 0.666992H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="628"
                              height="2"
                              viewBox="0 0 628 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H628" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex justify-start items-start w-[640px] absolute left-12 top-28 px-2">
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-08</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-09</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-10</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-11</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-12</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-13</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-14</p>
                        </div>
                      </div>
                      <svg
                          style="z-index: 10000000000000;position: inherit;"
                          width="540"
                          height="52"
                          viewBox="0 0 540 52"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-[540px] h-[52px]"
                          preserveAspectRatio="none"
                      >
                        <path d="M3 46L92 33L181 13L270 43L359 49L448 36L537 3" stroke="#2962FF"></path>
                        <circle cx="3" cy="46" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="92" cy="33" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="181" cy="13" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="270" cy="43" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="359" cy="49" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="448" cy="36" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="537" cy="3" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-start items-start flex-grow gap-6">
                  <div
                      class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4 p-4 rounded-lg border border-[#1a1f2e]"
                  >
                    <div
                        class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                        Asset Allocation
                      </p>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M8.00004 15.1663C4.04671 15.1663 0.833374 11.953 0.833374 7.99967C0.833374 4.04634 4.04671 0.833008 8.00004 0.833008C11.9534 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9534 15.1663 8.00004 15.1663ZM8.00004 1.83301C4.60004 1.83301 1.83337 4.59967 1.83337 7.99967C1.83337 11.3997 4.60004 14.1663 8.00004 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00004 1.83301Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8 9.16634C7.72667 9.16634 7.5 8.93967 7.5 8.66634V5.33301C7.5 5.05967 7.72667 4.83301 8 4.83301C8.27333 4.83301 8.5 5.05967 8.5 5.33301V8.66634C8.5 8.93967 8.27333 9.16634 8 9.16634Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8.00004 11.3329C7.91337 11.3329 7.82671 11.3129 7.74671 11.2796C7.66671 11.2463 7.59337 11.1996 7.52671 11.1396C7.46671 11.0729 7.42004 11.0063 7.38671 10.9196C7.35337 10.8396 7.33337 10.7529 7.33337 10.6663C7.33337 10.5796 7.35337 10.4929 7.38671 10.4129C7.42004 10.3329 7.46671 10.2596 7.52671 10.1929C7.59337 10.1329 7.66671 10.0863 7.74671 10.0529C7.90671 9.98626 8.09337 9.98626 8.25337 10.0529C8.33337 10.0863 8.40671 10.1329 8.47337 10.1929C8.53337 10.2596 8.58004 10.3329 8.61337 10.4129C8.64671 10.4929 8.66671 10.5796 8.66671 10.6663C8.66671 10.7529 8.64671 10.8396 8.61337 10.9196C8.58004 11.0063 8.53337 11.0729 8.47337 11.1396C8.40671 11.1996 8.33337 11.2463 8.25337 11.2796C8.17337 11.3129 8.08671 11.3329 8.00004 11.3329Z"
                            fill="white"
                        ></path>
                      </svg>
                    </div>
                    <div
                        class="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4"
                    >
                      <svg
                          width="152"
                          height="152"
                          viewBox="0 0 152 152"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-[152px] h-[152px] relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M127.426 112C120.381 124.201 109.508 133.737 96.4917 139.128C83.4755 144.52 69.0441 145.466 55.4355 141.819"
                            stroke="#5E77FF"
                            stroke-width="8"
                        ></path>
                        <path
                            d="M55.4356 141.819C44.5841 138.912 34.6891 133.199 26.7452 125.255C18.8013 117.311 13.0884 107.416 10.1808 96.5645"
                            stroke="#33C77F"
                            stroke-width="8"
                        ></path>
                        <path
                            d="M10.1807 96.5644C7.27308 85.7128 7.27308 74.2871 10.1807 63.4355C13.0884 52.584 18.8013 42.689 26.7452 34.7451"
                            stroke="#F2AF4B"
                            stroke-width="8"
                        ></path>
                        <path
                            d="M26.7451 34.7452C32.6881 28.8022 39.7434 24.088 47.5082 20.8717C55.2731 17.6554 63.5954 16 72 16"
                            stroke="#131722"
                            stroke-width="8"
                        ></path>
                        <path
                            d="M72 16C83.2343 16 94.2708 18.9572 104 24.5744C113.729 30.1915 121.808 38.2708 127.426 48C133.043 57.7292 136 68.7657 136 80C136 91.2343 133.043 102.271 127.426 112"
                            stroke="#FF8D07"
                            stroke-width="20"
                        ></path>
                      </svg>
                      <div class="flex flex-col justify-start items-end flex-grow gap-4">
                        <div
                            class="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative"
                        >
                          <div
                              class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-16 relative gap-2"
                          >
                            <div class="flex-grow-0 flex-shrink-0 w-1.5 h-1.5 bg-[#ff8d07]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm text-right text-white">Algo</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 w-14 text-sm text-right text-white">33.95%</p>
                        </div>
                        <div
                            class="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative"
                        >
                          <div
                              class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-16 relative gap-2"
                          >
                            <div class="flex-grow-0 flex-shrink-0 w-1.5 h-1.5 bg-[#5e77ff]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm text-right text-white">Euro</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 w-14 text-sm text-right text-white">23.49%</p>
                        </div>
                        <div
                            class="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative"
                        >
                          <div
                              class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-16 relative gap-2"
                          >
                            <div class="flex-grow-0 flex-shrink-0 w-1.5 h-1.5 bg-[#33c77f]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm text-right text-white">AVAX</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 w-14 text-sm text-right text-white">21.91%</p>
                        </div>
                        <div
                            class="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative"
                        >
                          <div
                              class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-16 relative gap-2"
                          >
                            <div class="flex-grow-0 flex-shrink-0 w-1.5 h-1.5 bg-[#f2af4b]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm text-right text-white">Pond</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 w-14 text-sm text-right text-white">17.03%</p>
                        </div>
                        <div
                            class="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative"
                        >
                          <div
                              class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-16 relative gap-2"
                          >
                            <div class="flex-grow-0 flex-shrink-0 w-1.5 h-1.5 bg-[#131722]"></div>
                            <p class="flex-grow-0 flex-shrink-0 text-sm text-right text-white">Other</p>
                          </div>
                          <p class="flex-grow-0 flex-shrink-0 w-14 text-sm text-right text-white">3.62%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                      class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[488px] relative gap-4 p-4 rounded-lg border border-[#1a1f2e]"
                  >
                    <div
                        class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-white">
                        Asset Net Worth
                      </p>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                          preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                            d="M8.00004 15.1663C4.04671 15.1663 0.833374 11.953 0.833374 7.99967C0.833374 4.04634 4.04671 0.833008 8.00004 0.833008C11.9534 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9534 15.1663 8.00004 15.1663ZM8.00004 1.83301C4.60004 1.83301 1.83337 4.59967 1.83337 7.99967C1.83337 11.3997 4.60004 14.1663 8.00004 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00004 1.83301Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8 9.16634C7.72667 9.16634 7.5 8.93967 7.5 8.66634V5.33301C7.5 5.05967 7.72667 4.83301 8 4.83301C8.27333 4.83301 8.5 5.05967 8.5 5.33301V8.66634C8.5 8.93967 8.27333 9.16634 8 9.16634Z"
                            fill="white"
                        ></path>
                        <path
                            d="M8.00004 11.3329C7.91337 11.3329 7.82671 11.3129 7.74671 11.2796C7.66671 11.2463 7.59337 11.1996 7.52671 11.1396C7.46671 11.0729 7.42004 11.0063 7.38671 10.9196C7.35337 10.8396 7.33337 10.7529 7.33337 10.6663C7.33337 10.5796 7.35337 10.4929 7.38671 10.4129C7.42004 10.3329 7.46671 10.2596 7.52671 10.1929C7.59337 10.1329 7.66671 10.0863 7.74671 10.0529C7.90671 9.98626 8.09337 9.98626 8.25337 10.0529C8.33337 10.0863 8.40671 10.1329 8.47337 10.1929C8.53337 10.2596 8.58004 10.3329 8.61337 10.4129C8.64671 10.4929 8.66671 10.5796 8.66671 10.6663C8.66671 10.7529 8.64671 10.8396 8.61337 10.9196C8.58004 11.0063 8.53337 11.0729 8.47337 11.1396C8.40671 11.1996 8.33337 11.2463 8.25337 11.2796C8.17337 11.3129 8.08671 11.3329 8.00004 11.3329Z"
                            fill="white"
                        ></path>
                      </svg>
                      <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-[#5b647b]">$7341.31</p>
                    </div>
                    <div class="self-stretch flex-grow-0 flex-shrink-0 h-[264px] relative overflow-hidden">
                      <div
                          class="flex flex-col justify-between items-center w-[456px] h-60 absolute left-0 top-0"
                      >
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="396"
                              height="2"
                              viewBox="0 0 396 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H396" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="396"
                              height="2"
                              viewBox="0 0 396 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 0.799805H396" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="396"
                              height="2"
                              viewBox="0 0 396 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 0.599609H396" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="396"
                              height="2"
                              viewBox="0 0 396 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1.40039H396" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="396"
                              height="2"
                              viewBox="0 0 396 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1.2002H396" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                        <div
                            class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]"
                        >
              <pre></pre
              >
                          <svg
                              width="388"
                              height="2"
                              viewBox="0 0 388 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-grow"
                              preserveAspectRatio="none"
                          >
                            <path d="M1 1H388" stroke="#1A1F2E" stroke-linecap="round"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex justify-start items-start w-[408px] absolute left-12 top-[248px] px-2">
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-08</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-09</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-10</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-11</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-12</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-13</p>
                        </div>
                        <div class="flex flex-col justify-start items-center flex-grow relative gap-4">
                          <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#5b647b]">09-14</p>
                        </div>
                      </div>
                      <svg
                          width="338"
                          height="140"
                          viewBox="0 0 338 140"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute left-[83px] top-[29px]"
                          preserveAspectRatio="none"
                      >
                        <path d="M1 139L57 61L113 100L169 42L225 1L281 133L337 94" stroke="#2962FF"></path>
                      </svg
                      >
                      <svg
                          width="342"
                          height="144"
                          viewBox="0 0 342 144"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-[342px] h-36"
                          preserveAspectRatio="none"
                      >
                        <circle cx="3" cy="141" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="59" cy="63" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="115" cy="102" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="171" cy="44" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="227" cy="3" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="283" cy="135" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                        <circle cx="339" cy="96" r="2.5" fill="#131722" stroke="#2962FF"></circle>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-b {
  width: 1350px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #131722;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 95vw;
}

@media (max-width: 450px) {
  .modal-container-b {
    max-width: 90vw;
  }
}

.modal-header h3, .modal-header .title {
  color: #b2b5be;
  font-size: 20px;
  margin-top: 0;
}

.modal-body-b {
  margin: 20px 0;
  max-height: 85vh;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-b,
.modal-leave-to .modal-container-b {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
