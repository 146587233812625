/**
 * Creating a mock of the datafeed
 * Tutorial: https://github.com/tradingview/charting-library-tutorial/blob/master/documentation/integration.md
 * Full implementation: https://github.com/tradingview/charting_library/wiki/JS-Api#how-to-start
 * Tutorial implementation: https://github.com/tradingview/charting-library-tutorial/blob/master/documentation/datafeed-implementation.md
 * Stream implementation: https://github.com/tradingview/charting-library-tutorial/blob/master/documentation/streaming-implementation.md
 */

import {checkInterval, getExchangeServerTime, getKlines, getSymbols, subscribeKline, unsubscribeKline} from './helpers'


const configurationData = {
    exchanges: [
        {
            "value": "",
            "name": "All Exchanges",
            "desc": ""
        },
        {
            "value": "BinanceFutures",
            "name": "BinanceFutures",
            "desc": "BinanceFutures"
        },
        {
            "value": "BinanceSpot",
            "name": "BinanceSpot",
            "desc": "BinanceSpot"
        },
        {
            "value": "KuCoin",
            "name": "KuCoin",
            "desc": "KuCoin"
        }
    ],
    symbols_types: [
        {
            name: "All types",
            value: ""
        },
        {
            name: "Futures",
            value: "futures"
        },
        {
            name: "Spot",
            value: "spot"
        }
    ],
    supports_search: true,
    supports_group_request: false,
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,
    supported_resolutions: [
        '1', '3', '5', '15', '30', '60', '120', '240', 'D', '1440', '1w', 'w'
    ]
};

export default {
    // get a configuration of your datafeed (e.g. supported resolutions, exchanges and so on)
    onReady: (callback) => {
        setTimeout(() => callback(configurationData)) // callback must be called asynchronously.
    },
    // NO need if not using search
    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        console.log('[searchSymbols]: Method call');
        let newSymbols = null;
        await getSymbols().then(symbols => {
            console.log('[searchSymbols]: symbols');
            console.log(symbols);
            newSymbols = symbols.filter(item => {
                const isExchangeValid = exchange === '' || item.exchange === exchange;
                const isFullSymbolContainsInput = item.symbol
                    .toLowerCase()
                    .indexOf(userInput.toLowerCase()) !== -1;
                return isExchangeValid && isFullSymbolContainsInput;
            });
        });
        console.log('[searchSymbols]: onResultReadyCallback');
        newSymbols = newSymbols.map(item => {
            return {
                name: item.symbol,
                symbol: item.symbol,
                full_name: item.symbol,
                ticker: item.symbol,
                description: item.baseAsset + ' / ' + item.quoteAsset,
                exchange: item.exchange,
                type: null,
            }
        })
        onResultReadyCallback(newSymbols);
    },
    // retrieve information about a specific symbol (exchange, price scale, full symbol etc.)
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {

        let exchangeName = null;
        if (window.exchange_id === 3) {
            exchangeName = "OKX";
        }
        if (window.exchange_id === 1 && window.exchange_id === 2) {
            exchangeName = "Binance";
        }

        // need for pricescale()
        function pricescale(symbol) {
            for (let filter of symbol.filters) {
                if (filter.filterType == 'PRICE_FILTER') {
                    return Math.round(1 / parseFloat(filter.tickSize))
                }
            }
            return 1
        }

        const symbolInfo = (symbol) => ({
            name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            ticker: symbol.symbol,
            // ticker: fullNamee,
            exchange: exchangeName,
            //listed_exchange: 'Binance',
            //type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbol), // 	or 100
            timezone: 'UTC',
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset
        })

        // Get symbols
        getSymbols().then(symbols => {
            const symbol = symbols.find(i => i.symbol == symbolName)
            return symbol ? onSymbolResolvedCallback(symbolInfo(symbol)) : onResolveErrorCallback('[resolveSymbol]: symbol not found')
        })

    },
    // get historical data for the symbol
    // https://github.com/tradingview/charting_library/wiki/JS-Api#getbarssymbolinfo-resolution-periodparams-onhistorycallback-onerrorcallback
    getBars: async (symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback) => {
        console.debug(interval);
        console.debug(checkInterval(interval));
        if (!checkInterval(interval)) {
            return onErrorCallback('[getBars] Invalid interval')
        }

        const klines = await getKlines({
            symbol: symbolInfo.name,
            interval,
            from: periodParams.from,
            to: periodParams.to
        })
        if (klines.length > 0) {
            return onHistoryCallback(klines)
        }

        onErrorCallback('Klines data error')

    },
    // subscription to real-time updates
    subscribeBars: (symbolInfo, interval, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        subscribeKline({symbol: symbolInfo.name, interval, uniqueID: subscribeUID,}, cb => onRealtimeCallback(cb))
    },
    unsubscribeBars: (subscriberUID) => {
        unsubscribeKline(subscriberUID)
    },
    getServerTime: (callback) => {
        getExchangeServerTime().then(time => {
            callback(Math.floor(time / 1000))
        }).catch(err => {
            console.error(err)
        })
    }
};
