const NodeWebSocket = require('ws')
const WS = typeof window !== 'undefined' ? WebSocket : NodeWebSocket // Crossplatform Client and Server

const {updateSockets} = require('./helpers')

const url = 'wss://fstream.binance.com/ws/'
// const url = 'wss://ws.finomate.io/stream'

// Rename options
const renameKeys = require("../../renameKeys")
const {streamSchema} = require('./schema')
const rename = (data) => {
    const event = (data.e || data[0] && typeof data[0].e != 'undefined') ? (data.e || data[0].e) : 'bookTicker'
    return renameKeys(streamSchema[event], data)
}

const setupWebSocket = ({path, uniqueID = false}, callback) => {

    const stream = new WS(url + path)
    // const stream = new WS(`${url}?streams=${path}&exchange_code=${window.exchange_id}`)
    console.log(url);
    console.log(path);
    // path = null;

    // Event stream
    stream.onopen = () => console.log('[socket] Connected to exchange')
    stream.onclose = () => console.log('[socket] Connected closed')
    stream.onmessage = (message) => callback(rename(JSON.parse(message.data)))
    stream.onerror = (err) => console.error(err)

    updateSockets({path, uniqueID}, stream) // Need to control open streams

    return stream
}

module.exports = setupWebSocket

//binance
// {"e":"kline","E":1718568658193,"s":"BTCUSDT","k":{"t":1718568540000, "T":1718568719999, "s":"BTCUSDT", "i":"3m", "f":5090794330, "L":5090800559, "o":"66509.20", "c":"66468.00", "h":"66509.30", "l":"66432.90", "v":"748.426", "n":4965, "x":false, "q":"49747937.30280", "V":"155.511", "Q":"10335441.65340", "B":"0"}}

// ahmad
// {"e": "kline", "E": 1718568737543, "k": {"t": 1718568720000, "T": 1718568899999, "s": "BTCUSDT", "i": "3m", "f": 5090800366, "L": 5090800559, "o": "66456.00", "c": "66466.20", "h": "66466.20", "l": "66455.90", "v": "10.070", "n": 194, "x": false, "q": "669261.29580", "V": "9.637", "Q": "640484.11230", "B": "0"}, "ps": "BTCUSDT", "ct": "PERPETUAL"}