<template>
  <div style="border-top: 1px solid #30333e;" class="relative p-4">
    <div class="flex justify-start items-center gap-3 mb-5">
      <div class="flex justify-between items-center flex-grow relative">
        <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Sum</p>
        <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Bid</p>
      </div>
      <div class="flex justify-between items-center flex-grow relative">
        <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Ask</p>
        <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-white">Sum</p>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-col justify-start items-start w-[50%]">
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden bg-[#152830]">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#131722]"></div>
          <p class="absolute left-0 top-2 text-xs text-left text-white">3,151</p>
          <p class="absolute right-[5px] top-1.5 text-sm font-medium text-right text-[#26a69a]">
            9137.40
          </p>
        </div>
      </div>
      <div class="flex flex-col justify-start items-start w-[50%]">
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.40
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-4 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9139.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-20 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.20
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-[120px] h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.10
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-36 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9138.00
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-32 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.60
          </p>
        </div>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-8 relative overflow-hidden">
          <div class="w-14 h-8 absolute left-[-1px] top-[-1px] bg-[#2d1e28]"></div>
          <p class="absolute left-[126px] top-2 text-xs text-right text-white">3,151</p>
          <p class="absolute left-2 top-1.5 text-sm font-medium text-left text-[#ef5350]">
            9137.40
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderBook"
}
</script>

<style scoped>

</style>