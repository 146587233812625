<template>
  <section class="overflow-hidden">
    <!-- order type -->
    <div class="flex justify-start items-start">
      <a href="javascript:;" @click="dOrder.order_type = 1"
         class="flex flex-col justify-start items-center flex-grow relative gap-2">
        <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left"
           :class="{'text-white' : dOrder.order_type === 1,'text-[#2a2e39]' : dOrder.order_type !== 1}">Open</p>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-0.5"
             :class="{'bg-[#2962ff]' : dOrder.order_type === 1,'bg-[#2a2e39]' : dOrder.order_type !== 1}"></div>
      </a>
      <a href="javascript:;" @click="dOrder.order_type = 2"
         class="flex flex-col justify-start items-center flex-grow relative gap-2">
        <p class="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left"
           :class="{'text-white' : dOrder.order_type === 2,'text-[#2a2e39]' : dOrder.order_type !== 2}">
          Close</p>
        <div class="self-stretch flex-grow-0 flex-shrink-0 h-0.5"
             :class="{'bg-[#2962ff]' : dOrder.order_type === 2,'bg-[#2a2e39]' : dOrder.order_type !== 2}"></div>
      </a>
    </div>

    <!-- trading type -->
    <div class="flex justify-start items-start overflow-hidden gap-7 rounded border-b border-[#2a2e39] mt-5 p-2">
      <a href="javascript:;" @click="dOrder.trading_type = 1"
         class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
         :class="{'text-white' : dOrder.trading_type === 1,'text-[#2a2e39]' : dOrder.trading_type !== 1}">Limit</a>
      <a href="javascript:;" @click="dOrder.trading_type = 2"
         class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
         :class="{'text-white' : dOrder.trading_type === 2,'text-[#2a2e39]' : dOrder.trading_type !== 2}">Market</a>
      <a href="javascript:;" @click="dOrder.trading_type = 3"
         class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
         :class="{'text-white' : dOrder.trading_type === 3,'text-[#2a2e39]' : dOrder.trading_type !== 3}">Stop-Limit</a>
      <a href="javascript:;" @click="dOrder.trading_type = 4"
         class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left"
         :class="{'text-white' : dOrder.trading_type === 4,'text-[#2a2e39]' : dOrder.trading_type !== 4}">OCO</a>
    </div>

    <!-- available wallet -->
    <div class="flex justify-start items-center gap-2 mt-5 mb-5">
      <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#35415f]">Available:</p>
      <p class="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-white">25000.0 USDT</p>
      <a href="javascript:;">
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0"
            preserveAspectRatio="xMidYMid meet"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16ZM7.5 4V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5H8.5V4H7.5Z"
              fill="#2962FF"
          ></path>
        </svg>
      </a>
    </div>

    <div v-if="[1,3,4].includes(dOrder.trading_type)">
      <!-- Price Input -->
      <div
          class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2">
        <label for="price"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f] min-w-[50px]">Price</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input v-model="dOrder.entity.price" id="price" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f]">USDT</span>
        </div>
      </div>
      <!-- Stop Input -->
      <div v-if="dOrder.trading_type === 4"
           class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2">
        <label for="stop"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f] min-w-[50px]">Stop</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input v-model="dOrder.entity.stop" id="stop" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f]">USDT</span>
        </div>
      </div>
      <!-- Limit Input -->
      <div v-if="[3,4].includes(dOrder.trading_type)"
           class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2">
        <label for="limit"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f] min-w-[50px]">Limit</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input v-model="dOrder.entity.limit" id="limit" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f]">USDT</span>
        </div>
      </div>
      <!-- Amount Input -->
      <div
          class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2">
        <label for="amount"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f] min-w-[50px]">Amount</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input v-model="dOrder.entity.amount" id="amount" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f]">BTC</span>
        </div>
      </div>
      <!-- Range Slider -->
      <my-range-slider v-model="dOrder.entity.percent"></my-range-slider>
      <!-- Total Input -->
      <div
          class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2 mt-12">
        <label for="total"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f] min-w-[50px]">Total</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input :value="dOrder.entity.amount * dOrder.entity.price" id="total" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f]">BTC</span>
        </div>
      </div>
    </div>

    <div v-if="dOrder.trading_type === 2">
      <!-- Amount Input -->
      <div
          class="flex justify-between items-center h-10 px-3 rounded bg-[#1a1f2e] my-2">
        <label style="border-right: 1px solid #4e4e4e;padding-right: 10px;"
               class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">Amount</label>
        <label class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#35415f]">Total</label>
        <div class="flex items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
          <input v-model="dOrder.entity.amount" id="amount" type="text" inputmode="decimal"
                 pattern="^[0-9]*[.,]?[0-9]+$"
                 class="w-[110px] sm:w-[160px] h-full py-0 text-sm font-medium text-right text-white bg-transparent border-none outline-none"
                 placeholder="0">
          <span
              class="flex-grow-0 flex-shrink-0 text-sm font-medium text-right text-[#35415f] min-w-[40px]">BTC</span>
        </div>
      </div>
      <!-- Range Slider -->
      <my-range-slider class="mb-12" v-model="dOrder.entity.percent"></my-range-slider>
    </div>

    <!-- Actions -->
    <div class="flex justify-start items-start gap-3 my-4">
      <div
          class="flex justify-center items-center flex-grow h-10 relative gap-[193px] rounded bg-[#26a69a]"
      >
        <a href="javascript:;" @click="dOrder.invalid = false"
           class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
          {{ dOrder.order_type === 1 ? 'Open' : 'Close' }} long
        </a>
      </div>
      <div class="flex justify-center items-center flex-grow h-10 relative gap-[193px] rounded bg-[#ef5350]">
        <a href="javascript:;" @click="dOrder.invalid = true"
           class="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
          {{ dOrder.order_type === 1 ? 'Open' : 'Close' }} short
        </a>
      </div>
    </div>

    <div v-if="dOrder.invalid"
         class="flex justify-start items-start gap-3">
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
          preserveAspectRatio="xMidYMid meet"
      >
        <path
            d="M14.6085 6.27519C14.8526 6.03112 14.8526 5.63539 14.6085 5.39131C14.3645 5.14723 13.9687 5.14723 13.7247 5.39131L9.99993 9.11604L6.2752 5.39131C6.03112 5.14723 5.6354 5.14723 5.39132 5.39131C5.14724 5.63539 5.14724 6.03112 5.39132 6.27519L9.11604 9.99992L5.39131 13.7247C5.14723 13.9687 5.14723 14.3645 5.39131 14.6085C5.63539 14.8526 6.03112 14.8526 6.27519 14.6085L9.99993 10.8838L13.7247 14.6085C13.9687 14.8526 14.3645 14.8526 14.6085 14.6085C14.8526 14.3645 14.8526 13.9687 14.6085 13.7247L10.8838 9.99992L14.6085 6.27519Z"
            fill="#EF5350"
        ></path>
      </svg>
      <p class="flex-grow w-72 text-xs text-left text-[#ef5350]">
        Error message. This is just a dummy random text. Any other error will be shown this way.
      </p>
    </div>


  </section>
</template>

<script>
import MyRangeSlider from "@/components/partials/myRangeSlider";

export default {
  name: "formBasicOrder",
  components: {MyRangeSlider},
  data() {
    return {
      dOrder: { // todo | set defaults
        showOrdersHistoryModal: false,
        showAnalyzModal: false,
        showModal: false,
        invalid: false,
        order_type: 1,
        trading_type: 1,
        entity: {
          amount: 0,
          price: 0,
          stop: 0,
          limit: 0,
          percent: 50
        }
      }
    }
  },
}
</script>

<style scoped>

</style>